import React, {Fragment} from "react";
import Esercizio from '../../services/esercizio';
import SearchBar from '../../components/SearchBar';
import OSMap from '../../components/OSMap';
import styles from './style.module.css';
import { __ } from './../../utils/translator'
import { fieldsInformation, privacy_fieldsInformation } from './fieldsForm';
import Form from "../../components/Form";
import _ from "lodash";
import BannerContatti from "../../components/BannerContatti";
import EsercizioSvc from "../../services/esercizio";
import IconText from "../../components/IconText";
import Appartamenti from '../../services/appartamenti';

export default class Contatti extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            ...props,
            pageContent: ''
        }

        this.esercizioSvc = new EsercizioSvc();
        this.appartamenti = new Appartamenti();
        this.onResetForm = this.onResetForm.bind(this);
        this.onContactSubmit = this.onContactSubmit.bind(this);

    }



    render(){
        const text = this.state.pageContent;
        const {
            esercizio,
            resetForm,
            onContactSubmitValidation,
        } = this.state;

        const bannerContatti = this.esercizioSvc.getBannerInfo('bannerContatti');

        const indirizzo = this.esercizioSvc.getIndirizzo();
        const numero = this.esercizioSvc.getNumero();
        const citta = this.esercizioSvc.getCitta();
        const telefono = this.esercizioSvc.getTelefono();
        const nome = this.esercizioSvc.getNome();
        const mail = this.esercizioSvc.getEmail();
        const lat = this.esercizioSvc.getLatitudine();
        const lng = this.esercizioSvc.getLongitutine();
        const cellulare = this.esercizioSvc.getRecapitoAggiuntivo('cellulare');
        const whatsapp = this.esercizioSvc.getRecapitoAggiuntivo('whatsapp');
        const facebook = this.esercizioSvc.getRecapitoAggiuntivo('facebook');
        const skype = this.esercizioSvc.getRecapitoAggiuntivo('skype');
        const youtube = this.esercizioSvc.getRecapitoAggiuntivo('youtube');


        if(!esercizio) return null;

        return(
            <Fragment>
                    {bannerContatti &&
                    <BannerContatti image={bannerContatti.image} title_h1={bannerContatti.title_h1} title_h2={bannerContatti.title_h2}  />
                    }
                <main className={[styles.contatti,'min-h-100'].join(' ')}>
                    <SearchBar
                        position='top'
                        search={false}
                        key='search_top'
                        esercizio={esercizio}
                        match={this.state.match}
                        history={this.props.history}
                        location={this.state.location} />
                    <div className={styles.centerColumn}>

                        <div className={['w-70', 'center'].join(' ')}>


                            <div className={['w-100'].join(' ')}>

                                <div id={'mappa'} className={styles.mappaContainer}>
                                    <div className={[styles.titolo, 'title-color', 'margin_bottom_'].join(' ')}>
                                        <h2><IconText text={__('Dove siamo')} icon={'icon-map'} /></h2>
                                        <p>{citta}</p>
                                        <p>{__('Tel')} : {telefono}<br/>
                                            {__('Mail')}:<span><a
                                                href={"mailto:" + mail}
                                                rel="alternate noopener">{mail}</a></span>
                                        </p>
                                        <p>Mobile : {cellulare}<br/>
                                            Whatsapp:<span><a
                                                href={"https://wa.me/" + whatsapp}
                                                rel="alternate noopener">{__('Apri la chat')}</a></span>
                                        </p>
                                        <p>Skype : bella.villa<br/>
                                            Facebook:<span><a
                                                href={facebook}
                                                rel="alternate noopener">{__('Seguici')}</a></span><br/>
                                            Youtube:<span><a
                                                href={youtube}
                                                rel="alternate noopener">{__('Seguici')}</a></span>
                                        </p>
                                    </div>
                                    <div className={styles.mappa}>
                                        <OSMap
                                            markerPosition={{
                                                lat: lat,
                                                lng: lng,
                                                zoom: 12
                                            }}
                                            nome={nome}
                                        />
                                    </div>
                                </div>

                            </div>


                        </div>

                        <div className={['w-70', 'center',styles.mainCol].join(' ')}>

                            <div id={'contatti'} className={styles.formContainer}>
                                <div className={[styles.titolo, 'title-color'].join(' ')}>
                                    <h2><IconText text={__('Richiedi informazioni')} icon={'icon-info'} /></h2>
                                    {(onContactSubmitValidation === 'errore') &&
                                    <p className={['align_center','color-error',styles.dinamicMessage].join(' ')}>{__(onContactSubmitValidation)}</p>
                                    }
                                    {(onContactSubmitValidation !== 'errore' && onContactSubmitValidation) &&
                                    <p className={['align_center','color-success',styles.dinamicMessage].join(' ')}>{__('Richiesta inviata con successo.') + ' (ID:' + onContactSubmitValidation +')' }</p>
                                    }
                                </div>
                                <div className={[styles.text, styles.richiestaInfoText].join(' ')}>

                                    <Form
                                        resetForm={resetForm}
                                        onResetForm={this.onResetForm}
                                        lang={esercizio.lang}
                                        fields={fieldsInformation}
                                        privacy={privacy_fieldsInformation}
                                        onSubmit={this.onContactSubmit}
                                        validationFields={esercizio.configurazioni.informazioni}
                                        legals={esercizio.configurazioni.legals}
                                        buttonLabel={__('Invia richiesta')} />
                                </div>
                            </div>

                        </div>

                    </div>
                </main>
            </Fragment>
        )
    }

    onResetForm() {
        this.setState({
            resetForm: false
        });
    }

    onContactSubmit(body,errors){
        const {
            esercizio

        } = this.state;

        const self = this;

        if (esercizio) {

            this.setState({ isLoading: true });
            this.appartamenti.postRichiestaInformazioni(body, esercizio, false, false, false, false, false, false )
                .then(({data}) => {
                    const id = data;
                    let formMessage = 'errore';
                    if(!id.error) {
                        window.dataLayer.push({
                            event: 'contact',
                            id: id,
                        });
                        formMessage = id;
                        self.setState({
                            isLoading: false,
                            onContactSubmitValidation: formMessage,
                            resetForm: true
                        });
                    } else {
                        self.setState({
                            isLoading: false,
                            onContactSubmitValidation: formMessage
                        });
                    }
                })
                .catch(err => {
                    console.log('err', err);
                });
        }
    }
}