// var dataa = []

export function __(text, ...args) {

  let translation = text;
  const translations = window.translations || {};

  // Se c'e l'or splitto la string, se l'argomento é > 1
  // prendo il singolare se no il plurale
  if (translation.indexOf('|') !== -1) {
    translation = translation.split('|')[args[0] > 1 ? 1 : 0];

    // Se non ho argomento metto al singolare
    if (args.length === 0) args = [1];
  }

  // Se non ho la traduzione passo la string non tradotta
  translation = translations[translation] || translation;
  if (translation.indexOf('%s') !== -1) {
    return translation
      .split("%s")
      .reduce((aggregate, chunk, i) =>
        aggregate + chunk + (args[i] === undefined ? "" : args[i]), "");
  }
  return translation;
}