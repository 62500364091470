import React from "react";
import {Link} from "react-router-dom";
import style from './style.module.css';
import Languages from '../../components/Languages';
import Esercizio from './../../services/esercizio';

export default class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      menuOpen: false,
    }

    this.onLanguageChange = this.onLanguageChange.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.brackpoint !== prevState.brackpoint) {
      return {
        ...nextProps
      };
    } else return null;
  }

  handleMenu(event) {
    this.setState({
      menuOpen: !this.state.menuOpen
    });
  }

  onLanguageChange(e) {
    var split = window.basename.split("/");
    var path = split.slice(0, split.length - 1).join("/") + "/" + e.target.value;

    window.location.assign(path);
  }

  render() {

    const esercizio = new Esercizio();

    const classes = [];
    const {menuOpen} = this.state;

    const menu = esercizio.getMenu();

    if (menuOpen) {
      classes.push(style['open']);
    }

    return (
      <header className={'primary-background'}>

        <a href={window.basename} className={style.logo}>
          <img src={`/api/logo/${esercizio.id}`}/>
        </a>

        <div className={[style.menuButton, (menuOpen) ? style.activeMenu : ''].join(' ')} onClick={this.handleMenu}><span className={style.navicon}></span></div>
        <nav className={classes.join(' ')}>
          <ul className={[style.menu, 'primary-color'].join(' ')}>
            {Object.keys(menu).map(key => {
              if (menu[key][1] !== false) {
                return <li key={key}><a className={'primary-color'} href={menu[key][1]} rel="noopener noreferrer">{menu[key][0]}</a></li>
              }
            })}
            <li><Link to={`/`} className={[style.active, 'primary-color'].join(' ')}>Booking</Link></li>
            <li><Languages className={'primary-color'} onChange={this.onLanguageChange} currentLang={esercizio.lang} langs={esercizio.configurazioni.languages}/></li>
          </ul>
        </nav>

      </header>
    )
  }
}
