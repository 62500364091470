import React, {
  Component
} from 'react';
import style from './style.module.css';
import { __ } from './../../utils/translator'

export default class ListHeader extends Component {

  constructor(props){
    super(props);
    this.state = {
      ...props,
      ordineTipo: props.ordineTipoDefault,
      ordineDirezione: props.ordineDirezioneDefault
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    const { total, currentPage, pages, pageLimit } = nextProps;
    if(nextProps !== prevState){
      return { total, currentPage, pages, pageLimit };
   }
   else return null;
 }

 
 render() {
  const { ordineTipo, ordineDirezione } = this.props;
  return (
    <div className={style.paginationInfo}>
      <div className={style.ordineTipo}>
        {ordineTipo && ordineTipo.map((item, index) => {
          return <span key={item} className={item === this.state.ordineTipo ? style.active : null} onClick={() => {
            this.setState({ ordineTipo: item });
            this.props.onSortByChange(item);
          }}>{__(item)}</span>
        })}
      </div>
      <div className={style.ordineDirezione}>
        {ordineDirezione && ordineDirezione.map((item, index) => {
          return <span key={item} className={item === this.state.ordineDirezione ? style.active : null} onClick={() => {
            this.setState({ ordineDirezione: item });
            this.props.onSortDirectionChange(item);
          }}>{__(item)}</span>
        })}
      </div>
      {this.props.additionalItem}
    </div>
  );
}

  renderOld() {
    const { total, currentPage, pages, pageLimit } = this.props;
    return (
      <div className={style.paginationInfo}>
        <h2>
          <strong>{total}</strong>{" "}
          Appartments
        </h2>
        {currentPage && (
          <span>
            currentPage 
            <span>{currentPage}</span> /{" "}
            <span>{pages}</span>
          </span>
        )}
        <select onChange={(e) => this.props.updateLimitValue(e)} value={pageLimit}>
          <option value="12">12</option>
          <option value="24">24</option>
          <option value="36">36</option>
          <option value="48">48</option>
        </select>
    </div>
    );
  }

}