import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from './container/Home'
import Search from './container/Search'
import Detail from './container/Detail'
import Legal from './container/Legal'
import Layout from './container/Layout'
import Confirmation from './container/Confirmation';
import _404_ from './container/_404_';
import Header from './components/Header';
import Footer from './components/Footer';
import Contatti from "./container/Contatti";
import Chisiamo from "./container/Chisiamo";

const WrapperLayout = ({component: Component, hideFooter = false, ...rest}) => {
  return (
    <Route {...rest} render={matchProps => (
      <Layout>
        <Header {...rest} {...matchProps}/>
        <Component {...rest} {...matchProps}/>
        {!hideFooter ? (
          <Footer {...rest} {...matchProps}/>
        ) : <></>}
      </Layout>
    )} />
  )
};

export default (props) => (
  <Switch>
    <WrapperLayout path='/' {...props} component={Home} hideFooter exact/>
    <WrapperLayout path='/privacy' {...props} component={Legal} exact/>
    <WrapperLayout path='/contatti' {...props} component={Contatti} exact/>
    <WrapperLayout path='/chisiamo' {...props} component={Chisiamo} exact/>
    <WrapperLayout path='/search' {...props} component={Search} hideFooter exact/>
    <WrapperLayout path='/search/:page(\d+)' {...props} component={Search} hideFooter exact/>
    <WrapperLayout path='/detail/:detail(\d+)' {...props} component={Detail} exact/>
    <WrapperLayout path='*/cookie' {...props} component={Legal} exact/>
    <WrapperLayout path='/newsletter' {...props} component={Legal} exact/>
    <WrapperLayout path='/regole' {...props} component={Legal} exact/>
    <WrapperLayout path='/informazioni' {...props} component={Legal} exact/>
    <WrapperLayout path='/prenotazione' {...props} component={Legal} exact/>
    <WrapperLayout path='/confirmation/:seed/:check' {...props} component={Confirmation} exact />
    <WrapperLayout path='/:page(\d+)' {...props} component={Home} hideFooter exact/>
    <WrapperLayout path='/' {...props} component={Home} exact/>
    <WrapperLayout path='*' {...props} component={_404_} />
  </Switch>
);