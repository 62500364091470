import React from "react";
import L, { marker } from "leaflet";
import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet';
import { markerIcon } from './Marker';


const style = {
  width: "100%",
  height: "500px"
};

class OSMap extends React.Component {

  // componentDidMount() {
  //   // create map
  //   const { markerPosition } = this.props;
  //   this.map = L.map("map", {
  //     center: [markerPosition.lat, markerPosition.lng],
  //     zoom: markerPosition.zoom,
  //     layers: [
  //       L.tileLayer("https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw", {
  //         id: 'mapbox.streets'
  //       })
  //     ]
  //   });

  //   // add marker
  //   this.marker = L.marker(this.props.markerPosition).addTo(this.map);
  // }
  // componentDidUpdate({ markerPosition }) {
  //   // check if position has changed
  //   if (this.props.markerPosition !== markerPosition) {
  //     this.marker.setLatLng(this.props.markerPosition);
  //   }
  // }
  // render() {
  //   return <div id="map" style={style} />;
  // }

  render() {
    const { markerPosition, nome } = this.props;
    
    return (
      <LeafletMap
        center={[markerPosition.lat, markerPosition.lng]}
        zoom={markerPosition.zoom}
        maxZoom={16}
        attributionControl={true}
				zoomControl={true}
				doubleClickZoom={true}
				scrollWheelZoom={false}
				dragging={true}
				animate={true}
				easeLinearity={0.35}
        >
        <TileLayer
          url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
        />
        <Marker position={[markerPosition.lat, markerPosition.lng]} 
        icon={ markerIcon }
        >
          <Popup>
            <h4 className='title-color'>{nome}</h4>
          </Popup>
        </Marker>
      </LeafletMap>
    );
  }
}

export default OSMap;
