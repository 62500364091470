import React, { Component } from "react";
import style from './style.module.css';

export default class CounterNumber extends Component {

  constructor(props){
    super(props);
    this.state = {
      ...props
    };
  }

  componentDidUpdate(prevProps, prevState){
    
    let newState = {}

    if (prevProps.maxValue !== this.props.maxValue) {
      newState = {
        ...newState,
        maxValue: this.props.maxValue
      };
    }
    if (prevProps.count !== this.props.count) {
      newState = { 
        ...newState,
        count: this.props.count 
      };
    }

    if (Object.keys(newState).length > 0) {
      this.setState({ 
        ...newState 
      });
    }
  }

  onCountChange(event){
    const { count } = event;
    this.setState({ count });
    this.props.onCountChange && this.props.onCountChange(event)
  }

  render (){
    const { count = 1, name, label, maxValue = 20} = this.state;
    return (
      <div className={[style.counter, 'noselect'].join(' ')} ref={name}>
        {label && <label className={style.small}>{label}</label>}
        <div className={[style.counters, 'title-color'].join(' ')}>
          <div className={[style.button, 'noselect', count <= 1 ? style.disable : ''].join(' ')} onClick={() => this.onCountChange({ name, count: (count > 1) ? parseInt(count) - 1 : 1 })}>-</div>
          <span className={[style.count, 'title-color'].join(' ')}>{count}</span>
          <div className={[style.button, 'noselect', count === maxValue ? style.disable : ''].join(' ')} onClick={() => this.onCountChange({ name, count: (count < maxValue) ? parseInt(count) + 1 : maxValue })}>+</div>
        </div>
      </div>
    )
  }
}

