import React, { Component, Fragment } from 'react';
import Gallery from './../Gallery';
import Button from './../Button';
import IconText from './../IconText';
import style from './style.module.css';
import _ from 'lodash';
import { __ } from '../../utils/translator';
import {getTitoloAppartamento} from "../../utils";

export default class CardAppartamento extends Component {

  constructor(props){
    super(props);

    this.state = {
      ...props
    };

    this.esercizio = window.esercizio;
    this.lang = this.esercizio && this.esercizio.lang;
    this.getDistanza = this.getDistanza.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps !== prevState){
      return { 
        ...nextProps
      };
    }
    else return null;
  }

  getDistanza(punto){

    const { data: { residence: { distanze }} } = this.props;
    
    if (distanze) {
      let distanzaDalPunto = '';
      distanze.filter(distanza => {
        if (_.get(distanza,'zona') && distanza.zona.nome === punto) {
          distanzaDalPunto = `${distanza.distanza} ${__('m dal '+ distanza.zona.nome)}` || '';
        }
      });
      return distanzaDalPunto;
    }
  }

  onHoverChange = (start) => {
    if(this.props.onHoverChange) {
      this.props.onHoverChange(start)
    }
  }

  render(){
    const { data, mode } = this.props;
    if(!data) {
      return null;
    }

    let location = '';
    if (this.props.location) {
      location = this.props.location;
    }

    let show_price = false;
    let min_price = 99999999;
    let retta_min = [];
    if(_.get(data,'rette')) {
      const rette = _.get(data,'rette',null);
      if(rette && Object.values(rette).length > 0){
        Object.values(rette).map((val,indx) => {
          if(_.get(val,'prezzo',99999999) < min_price){
            min_price = val.min_price
            retta_min = val;
          }
        })
      }
      if(retta_min !== []) {
        show_price = true;
      }
    }

    let showGallery = false;
    let fotografie = [];
    let fotoApp = data.fotografie.map(obj=> ({ ...obj, src: '/api/appartamenti/image/' + data.residence.agenzia + '/' + data.residence.id + '/' + data.id + '/' + obj.id }));
    let fotoRes = data.residence.fotografie.map(obj=> ({ ...obj, src: '/api/appartamenti/residences/image/' + data.residence.agenzia + '/' + data.residence.id + '/' + obj.id }));

    fotografie = fotoApp.concat(fotoRes);

    if(fotografie && fotografie.length){
        showGallery = true;
    }

    return (
      <article
        key={data.id}
        onMouseEnter={() => this.onHoverChange(true)}
        onMouseLeave={() => this.onHoverChange(false)}
        className={[style.single_card,style[`card-${mode}`]].join(' ')}
      >
        <div className={style.inner} >
            <div className={style.gallery}>
              {(showGallery) ?
              <Gallery 
                id={data.id}
                images={fotografie}
                paginationAlign='align_left'
                options={{
                  navigation: {
                    nextEl: `.swiper-button-next-${data.id}`,
                    prevEl: `.swiper-button-prev-${data.id}`,
                  },
                  pagination: {
                    el: `.swiper-pagination-${data.id}`,
                    type: 'bullets',
                    clickable: true
                  }
                }}
              />
              :
              <div className={[style.noimgcontain, 'imgcontain'].join(' ')}>
                <img src={require('./img/noimage-bookingmercurio.jpg')} alt={'no-image'} className={'wh'} />
              </div>
              }
              {show_price && 
                <div className={['secondary-background', style.prezzo].join(' ')}>
                  
                  {(retta_min.prezzo_base > retta_min.prezzo) ? 
                    <div className={style.prezzoinner}>
                        <span className={style.offerta} >{__('Offerta')}</span>
                        <sup className={style.prezzo_base} >€ {parseFloat(retta_min.prezzo_base).toFixed(2)}</sup> 
                        <span className={['nowrap',style.prezzo_intero].join(' ')} >€ {parseFloat(retta_min.prezzo).toFixed(2)}</span>
                    </div>
                    : 

                    <div>
                      <span className={style.offerta} >{__('Prezzo')}</span>
                      <span className={['nowrap',style.prezzo_intero_da].join(' ')} ><span className={style.apartire}>{__('da_euro')}</span> € {parseFloat(retta_min.prezzo).toFixed(2)}</span>
                    </div>
                  } 

                </div>
              }
            </div>
            <div className={[style.descrizione, 'tertiary-background'].join(' ')}>
              <div className={style.testo}>
                <h3>{getTitoloAppartamento(data, _.get(this.esercizio,'configurazioni.titolo_appartamento', ''))}</h3>
                {/* {data.descrizioni && data.descrizioni[this.lang] && `${data.descrizioni[this.lang].testo.slice(0, 100)}...`}   NEL CASO DI CACHE GENERALE E TESTI SALVATI IN UNICO ARRAY MULTILINGUA  */}
                {/* {_.get(data,'descrizione.testo') && <p>{data.descrizione.testo.slice(0, 110)}...</p>}   NEL CASO DI CACHE X LINGUA - possibilità di rimuovere i campi "descrizioni" con traduzioni   */}
                {_.get(data,'descrizione.testo') && <p>{data.descrizione.testo.slice(0, 110)}...</p>}
                {_.get(data,'residence.zona.nome') && <IconText className='title-color' text={`${data.residence.zona.nome}`} icon={'icon-map'} /> }
              </div>
              <div className={style.info}>
                {_.get(data,'posti_max') && <IconText className='title-color' text={__('%s posto letto|%s posti letto',data.posti_max)} icon={'icon-bed'} />}
                {_.get(data,'camere') != 0 && <IconText className='title-color' text={__('%s camera|%s camere',data.camere)} icon={'icon-key'} />}
                {this.getDistanza('mare') && <IconText className='title-color' text={`${this.getDistanza('mare')}`} icon={'icon-umbrella'} /> }
                <div className={style.btn_container} >
                <Button href={`${window.basename}/detail/${data.id}${location}`} label={__('Scopri di più')} bgColor={'secondary-background'} />
                </div>
              </div>
            </div>
        </div>
      </article>
    )
  }
}    