import React, { Component } from "react";
import style from './style.module.css';
import { __ } from './../../utils/translator';

export default class Counter extends Component {

  constructor(props){
    super(props);
    this.state = {
      ...props
    };
  }

  componentDidUpdate(prevProps, prevState){
    if (prevProps.count !== this.props.count) {
      this.setState({
        count: this.props.count
      })
    }
  }

  onCountChange(event){
    const { count } = event;
    this.setState({ count });
    this.props.onCountChange && this.props.onCountChange(event)
  }

  render (){
    const { count = 1, label, name, maxValue = 20} = this.state;
    return (
      <div className={style.counter} ref={name}>
        <div className={style.labelContainer}>
          <span className={[style.count, 'title-color'].join(' ')}>{count}</span>
          <span className={[style.label, 'text-color'].join(' ')}>{__(label,count)}</span>
        </div>
        <div className={[style.counters, 'title-color'].join(' ')}>
          <div className={[style.button, 'noselect'].join(' ')} onClick={() => this.onCountChange({ name, count: (count > 1) ? parseInt(count) - 1 : 1 })}>-</div>
          <div className={[style.button, 'noselect'].join(' ')} onClick={() => this.onCountChange({ name, count: (count < maxValue) ? parseInt(count) + 1 : maxValue })}>+</div>
        </div>
      </div>
    )
  }
}

