import React from 'react';
import { __ } from './../../utils/translator';
import style from './style.module.css';
import SearchBar from '../../components/SearchBar';

export default class _404_ extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      ...props
    }
  }
  
  render(){
    
    const text = this.state.pageContent;
    const { esercizio } = this.state;

    if(!esercizio) return <main className={[style.page404].join(' ')}>
      <div className={['align_center',style.centerColumn].join(' ')}>
        <div className={'text'} >
            <h1>{__('404 - Pagina Non Trovata')}</h1>
            <p><a href={window.basename}>{__('Clicca qui per tornare alla Home')}</a></p>
        </div>
      </div>
    </main>;

    return(
      <main className={style.page404}>
        <SearchBar 
          position='top'
          search={false}
          key='search_top'
          esercizio={esercizio} 
          match={this.state.match}
          history={this.props.history}
          location={this.state.location} />
        <div className={['align_center',style.centerColumn].join(' ')}>
          <div className={'text'} >
              <h1>{__('404 - Pagina Non Trovata')}</h1>
              <p><a href={window.basename}>{__('Clicca qui per tornare alla Home')}</a> {__('oppure prova con una nuova ricerca')} </p>
          </div>
        </div>
      </main>
    )
  }
}