import React, { Fragment, Component } from "react";
import style from './style.module.css';
import { __ } from './../../utils/translator';
import CounterNumber from './../CounterNumber';
import _ from 'lodash';

export default class ServizioAggiuntivo extends Component {

  constructor(props){
    super(props);
    this.state = {
      selectedGiorni: 0,
      selectedPersone: 0,
      selectedMax: 0,
      prezzo_servizio: 0,
      isAggiunto: false,
      ...props
    };
    this.onCountChange = this.onCountChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeVal = this.onChangeVal.bind(this);
  }

  componentDidUpdate(prevProps, prevState){
    const { detailPage , position } = this.state;
    
    let newState = {}


    if (prevProps.giorni !== this.props.giorni) {
      newState = {
        ...newState,
        giorni: this.props.giorni
      };
    }
    if (prevProps.max !== this.props.max) {
      newState = { 
        ...newState,
        max: this.props.max 
      };
    }
    if (prevProps.persone !== this.props.persone) {
      newState = {
        ...newState,
        persone: this.props.persone
      }
    }
    if (prevProps.prezzo !== this.props.prezzo) {
      newState = {
        ...newState,
        prezzo: this.props.prezzo
      }
    }
    if (prevProps.servizio !== this.props.servizio) {
      newState = {
        ...newState,
        servizio: this.props.servizio
      }
    }

    if (Object.keys(newState).length > 0) {
      this.setState({ 
        ...newState 
      });
    }
  }

  onChangeVal(event) {
    const state = this.state;
    this.props.onChangeVal && this.props.onChangeVal(event.target.id,state);
  }

  onCountChange(event) {
    const { name, count } = event;
    const state = this.state;
    if(count > state[name]) {
      state['isAggiunto'] = true;
      state.selectedGiorni = state.selectedGiorni || 1;
      state.selectedPersone = state.selectedPersone || 1;
      state.selectedMax = state.selectedMax || 1;
    }
    state[name] = count;
    let prezzo = 0;

    if (state['isAggiunto']) {
      prezzo = _.get(state,'prezzo',0);
      if (_.get(state,'giorni',false) ) {
        prezzo = prezzo * state.selectedGiorni;
      }
      if (_.get(state,'persone',false) ) {
        prezzo = prezzo * state.selectedPersone;
      }
      // prevedo il max
      if (_.get(state,'max',false) ) {
        prezzo = prezzo * state.selectedMax;
      }
    }
    state['prezzo_servizio'] = prezzo;
    this.setState(state);

    this.props.onChangeVal && this.props.onChangeVal(state.id,state);
  }

  onChange(e){
    const target = e.target;
    const state = this.state;
    let prezzo = 0;
    if (target.type === 'checkbox') {
      if (target.checked) {
        state['isAggiunto'] = true;

        prezzo = _.get(state,'prezzo',0);
        if (_.get(state,'giorni',false) ) {
          state.selectedGiorni = state.selectedGiorni || 1;
          prezzo = prezzo * state.selectedGiorni;
        }
        if (_.get(state,'persone',false) ) {
          state.selectedPersone = state.selectedPersone || 1;
          prezzo = prezzo * state.selectedPersone;
        }
        // prevedo il max
        if (_.get(state,'max',false) ) {
          state.selectedMax = state.selectedMax || 1;
          prezzo = prezzo * state.selectedMax;
        }
        
      } else {
        state['isAggiunto'] = false;
        state.selectedGiorni = 0;
        state.selectedMax = 0;
        state.selectedPersone = 0;
      } 
    }

    state['prezzo_servizio'] = prezzo;
    this.setState({ state });
  }

  render (){

    const { giorni = 0, persone = 0, max = 1, servizio, selectedGiorni, selectedPersone, selectedMax, prezzo_servizio = 0, isAggiunto } = this.state;

    // @todo il max indica quanti servizi sono acquistabili, al posto del checkbox dovrebbe essere un numero
    const titolo = _.get(servizio,'titolo.testo','');
    const descrizione = _.get(servizio,'descrizione.testo','');
    const id = _.get(servizio,'id','');

    return (
      <div className={[style.serviceGroup,(giorni > 0)? style.hasCounters :'',(isAggiunto)? style.hasCountersVisibles :''].join(' ')} onChange={this.onChangeVal} >
        <div className={style.input_group}>
          <input checked={this.state.isAggiunto} type={'checkbox'} name={`${id}`} id={`${id}`} onChange={this.onChange}/>
          <label htmlFor={`${id}`} className={['text-color','noselect'].join(' ')}>{titolo}</label>
          {descrizione && <small><br/>{descrizione.split('\n').map((item, key) => {
            return <Fragment key={key}>{item}<br/></Fragment>
          })}</small>}
        </div>
        <div className={style.serviceInfos}>
          {(max > 1) &&
            <Fragment>
              <div className={[style.smallCounterContainer,style.first_el].join(' ')}>
                <CounterNumber onCountChange={this.onCountChange} label={__('Qtà')} name={'selectedMax'} count={selectedMax} maxValue={max} />
              </div>
            </Fragment>
          }
          {(persone > 0) &&
            <Fragment>
              <div className={[style.smallCounterContainer,style.first_el].join(' ')}>
                <CounterNumber onCountChange={this.onCountChange} label={__('Persone')} name={'selectedPersone'} count={selectedPersone} maxValue={persone} />
              </div>
            </Fragment>
          }
          {(giorni > 0) &&
            <Fragment>
              <div className={[style.smallCounterContainer,style.second_el].join(' ')}>
                <CounterNumber onCountChange={this.onCountChange} label={__('Giorni')} name={'selectedGiorni'} count={selectedGiorni} maxValue={giorni} />
              </div>
            </Fragment>
          }
          <div className={[style.prezzo,'text-color','noselect'].join(' ')}>€ {parseFloat(prezzo_servizio).toFixed(2)}</div>
        </div>
      </div>
    )
  }
}

