import React from "react";
import Esercizio from '../../services/esercizio';
import SearchBar from '../../components/SearchBar';
import styles from './style.module.css';
import _ from 'lodash';
import {__} from './../../utils/translator'

export default class Legal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ...props,
      pageContent: ''
    }

    this.existLegal = this.existLegal.bind(this);
  }

  existLegal(tipo, object, path) {
    // seleziono il link dalle configurazioni se il tipo è 'link'
    if (tipo === 'link') {
      return null;
    } else { // senno linko alla pagina interna
      return _.get(object, `${path}.parametri`, '');
    }
  }

  componentDidMount() {
    const path = _.get(this.props, 'location.pathname').replace('/', '');
    if (path.includes('/')) {
      return document.location.href = window.basename + `/${path.split('/').reverse()[0]}`
    }

    const self = this;
    const esercizio = new Esercizio();
    const legalPath = path.split('/').reverse()[0];
    const legalObject = _.get(this.state, 'esercizio.configurazioni.legals');
    const tipo = _.get(legalObject, `${legalPath}.tipo`, '');
    const legalType = this.existLegal(tipo, legalObject, `${legalPath}`);

    if (tipo == 'cookie-script') {
      const s = esercizio.getCookieScriptElement();
      this.instance.appendChild(s);

      self.setState({pageContent: __('Cookie')});
    } else if (legalType) {
      if (legalType == 'regole') {// @todo hack perché le regole hanno url errata
        const {lang} = this.state.esercizio;
        esercizio.getAgencyInfo(legalType).then(function (response) {
            self.setState({pageContent: response.data[lang].testo});
          })
          .catch(function (error) {
            self.setState({pageContent: 'Contenuto rimosso' });
            console.log(error);
          });
      } else {
        esercizio.getLegalContent(legalType).then(function (response) {
            self.setState({pageContent: response.data, esercizio});
          })
          .catch(function (error) {
            self.setState({pageContent: 'Contenuto rimosso' });
            console.log(error);
          });
      }
    }
  }

  render() {
    const text = this.state.pageContent;
    const {esercizio} = this.state;

    if (!esercizio) return null;

    return (
      <main className={[styles.legal]}>
        <SearchBar
          position='top'
          search={false}
          key='search_top'
          esercizio={esercizio}
          match={this.state.match}
          history={this.props.history}
          location={this.state.location}/>
        <div className={styles.centerColumn}>
          {<div className={'text'} contentEditable='false' dangerouslySetInnerHTML={{__html: text}}></div>}
          {<div ref={el => (this.instance = el)}/>}
        </div>
      </main>
    )
  }
}