import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes'
import {Router} from 'react-router-dom'
import createBrowserHistory from "history/createBrowserHistory";
import cssVars from 'css-vars-ponyfill';
import _ from 'lodash';
import Esercizio from './services/esercizio';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'

const esercizio = new Esercizio();
window.esercizio = esercizio;

const {type, lang, id, configurazioni} = esercizio;

if (configurazioni.codice_analytics) {
  let id_analytics = configurazioni.codice_analytics;
  ReactGA.initialize(id_analytics);
  ReactGA.pageview(window.location.pathname + window.location.search);
}
if (configurazioni.codice_tagmanager) {
  const tagManagerArgs = {
    gtmId: configurazioni.codice_tagmanager
  }
  TagManager.initialize(tagManagerArgs);
  window.dataLayer.push({
    event: 'pageview'
  });
}

if (window.basename === '/undefined') {
  ReactDOM.render((
    <div>Sito non trovato</div>
  ), document.getElementById('root'));
} else {
  cssVars({
    variables: _.get(window.esercizio, 'color')
  });

  const history = createBrowserHistory({basename: window.basename});
  ReactDOM.render((
    <Router history={history}>
      <Routes esercizio={esercizio}/>
    </Router>
  ), document.getElementById('root'))
}
