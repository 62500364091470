import React, { Component } from 'react';
import style from './style.module.css';

export default class SelectMask extends Component {
  render() {   
    const { label, text, className, icon, onClick } = this.props;
    return (
      <div className={style.selectMaskOut}>
        <div className={[style.label,'title-color'].join(' ')}>{label}</div>
        <div className={[style.selectMask, className].join(' ')} onClick={onClick}>
          <div className={style.text}>{text}</div>
          <i className={[style.icon, icon].join(' ')}></i>
        </div>
        {this.props.children}
      </div>
    )
  }
}