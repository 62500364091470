import React from "react";
import style from './style.module.css';

export default class IconText extends React.Component{

  render (){
    const { icon, text, className, size, font_weight, onClick, textColor, style: itemStyle } = this.props;
    return (
      <div className={[style.iconText, style[size], className].join(' ')} onClick={onClick} style={itemStyle}>
        <i className={[style.icon, icon].join(' ')}></i>
        <div className={[style.text,style[`font_${font_weight}`],textColor].join(' ')} >
          {text}
        </div>
            
      </div>
    )
  }
}

