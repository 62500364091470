export const noEmpty = (string) => {
  return string && string.length > 0 ? true : 'Il campo non puo essere vuoto';
}

export const telephone = (telephone) => {
  var re = /^[+]*[0-9]{1,4}[\s0-9]{7,}/;
  return re.test(telephone) === true ? true : 'Devi inserire un numero di telefono corretto';
}

export const privacy = (privacy) => {
  return privacy === true ? true : 'Devi accettare l\'informativa sopraindicata';
}

export const select = (select) => {
  return select && select.length > 0 ?  true : 'Il campo non puo essere vuoto';
}

export const  validateEmail = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase()) === true ? true : 'Verifica l\'email';
}
