import React, {Fragment} from "react";
import Esercizio from '../../services/esercizio';
import SearchBar from '../../components/SearchBar';
import BannerChisiamo from "../../components/BannerChisiamo";
import styles from './style.module.css';
import _ from 'lodash';
import { __ } from './../../utils/translator'
import EsercizioSvc from "../../services/esercizio";
import IconText from "../../components/IconText";
import OSMap from "../../components/OSMap";
import Form from "../../components/Form";
import {fieldsInformation, privacy_fieldsInformation} from "../Contatti/fieldsForm";
import BannerContatti from "../../components/BannerContatti";

export default class Legal extends React.Component {

  constructor(props){
    super(props);

    this.esercizioSvc = new EsercizioSvc();

    this.state = {
      ...props, 
      pageContent: ''
    }
  }

  componentDidMount(){
    const self = this;
    const esercizio = new Esercizio();
    
  }


  
  render(){
    const text = this.state.pageContent;
    const { esercizio } = this.state;
    if(!esercizio) return null;
    const bannerChisiamo = this.esercizioSvc.getBannerInfo('bannerChisiamo');

    return(
        <Fragment>
          {bannerChisiamo &&
          <BannerChisiamo image={bannerChisiamo.image} title_h1={bannerChisiamo.title_h1} title_h2={bannerChisiamo.title_h2}  />
          }
          <main className={[styles.chisiamo,'min-h-100'].join(' ')}>
            <SearchBar
                position='top'
                search={false}
                key='search_top'
                esercizio={esercizio}
                match={this.state.match}
                history={this.props.history}
                location={this.state.location} />
            <div className={styles.centerColumn}>

              <div className={['w-70', 'center', ,styles.mainCol].join(' ')}>

                <div className={[styles.titolo, 'title-color', 'margin_bottom_25'].join(' ')}>
                  <h2>{__('Ciao siamo tre sorelle Michela, Monica e Debora Zenatti')}<br/>
                    {__('Abbiamo fatto della casa il nostro lavoro e la nostra passione.')}
                  </h2>
                </div>
                <div className={[styles.imagechisiamo,'w-90'].join(' ')}>

                  <img src={"/build/2615/chisiamo.jpg"} />

                </div>

                <div className={[styles.testolibero]}>

                  <p><strong>{__('Bella Villa Holiday Apartments')}</strong> {__('è una nostra avvincente avventura nata dall’idea di ')}<strong>{__('accogliere ospiti provenienti da tutto il mondo, per condividere il nostro splendido territorio, facendo sentire ogni nostro gradito ospite a casa!!')}</strong>.</p>

                  <p><strong>{__('Disponiamo di appartamenti di diverse dimensioni sul Lago di Garda o sul vicino Monte Baldo per farti trascorrere al meglio le tue vacanze per rilassarti con la tua dolce metà, goderti la tua famiglia, divertirti con gli amici o semplicemente riposarti.')}</strong></p>

                  <p><strong>{__('Ti piace fare o seguire lo Sport?')}</strong>
                    {__('Avrai la possibilità di provare qualsiasi sport al Lago dalla canoa, surf, vela oppure potrai pescare oppure ancora passeggiare sul nostro lago o semplicemente prendere il sole in spiaggia. Numerose sono le regate internazionali organizzare dal nostro Circolo velico Fraglia Vela Riva  tra le quali: Lake Garda Meeting Optimist Class, Melges 20, 24, 32, GC32  e tante altre. Se questo non ti basta potrai, anche con guide esperte, sia al Lago o sul Monte Baldo praticare nordic walking nei vicini sentieri, fare arrampicate in montagna, esplorare bellissime zone in montain bike o e-bike e godere, immersi nel silenzio, di fantastici panorami vicino al Lago o sul Monte Baldo. In zona potrai travare tanti eventi e competizioni dedicati come il famoso Bike Festival Garda Trentino dedicato al mondo delle Montain bike e e-bike,  Rock Master, Trofeo Città di Arco “Beppe Viola” MXGP Motocross,  Tour Transalp, Gran Prix della montagna, Festival Scacchistico, International Lake Garda Marathon')}
                  </p>

                  <p><strong>{__('Se invece quello che cerchi è musica, ballo, divertimento e mangiare bene')}</strong>
                    {__('nella nostra zona potrai svagarti con i tuoi amici nei numerosi locali dove deliziare il tuo palato con i squisiti piatti tipici accompagnati da buon vino o da particolari birre anche artigianali oppure partecipare alle numerose feste in piazze. Tra le più importanti ricordiamo la famosa Notte di Fiaba, Chocomusic Riva del Garda, Rustico Medioevo, Di Gusto in Gusto.')}
                  </p>

                  <p><strong>{__('Ti appassiona la musica o il teatro?')}</strong>
                    {__('potrai farti coinvolgere dai numerosi concerti, concorsi o spettacoli di generi molto diversi dal jazz, alla lirica, al teatro o goderti un’emozionante opera nella vicina Arena di Verona. Qui ti citiamo solo alcuni eventi ai quali potrai partecipare: Concorso corale internazionale, Filocorno d’Oro, Concorso internazionale per giovani cantanti lirici “Riccardo Zandonai”, MusicaRivaFestival, Garda Jazz Festival, Early Music Weeks, Drodesera, Festival Oriente Occidente.')}</p>


                  <p><strong>{__('Ti entusiasma la cultura?')}</strong>
                    {__('Il nostro territorio ricco di storia, cultura e tradizione ti da la possibilità di visitare numerosi ed interessanti musei tra i quali il MAG Museo Alto Garda, Galleria Civica Giovanni Segantini, Museo delle Palafitte di Fiavè, il Mart Museo d’arte moderna e contemporanea o l’innovativo Muse Museo delle Scienze o il Museo degli usi e costumi della gente trentina e tanti altri oppure sognare negli affascinanti castelli tra i quali: Castello di Arco, Castello di Drena, Castello di Stenico, FAI Castello di Avio, Castello di Rovereto, Castel Pietra, Castel Beseno, Museo Castello del Buonconsiglio, Castel Thun e tanti altri o visitare incantevoli città italiane vicine alla nostra zona tra le quali: Verona, Bolzano, Mantova, Milano, Bologna, Padova, Venezia, Innsbruck oltre a numerosi, vicini, piccoli e speciali borghi e luoghi: il piccolo Lago di Tenno color smeraldo con il sul borgo caratteristico, il lago di Ledro incastonato nelle montagne, il fiabesco Lago di Toblino con il suo Castello, il surreale Lago di Cavedine e molti altri.')}</p>

                  <p><strong>{__('Devi invece raggiungere Riva del Garda per lavoro o per partecipare ad uno dei numerosi congressi internazionali o per intervenire in una delle Fiere che qui vengono organizzate:')}</strong>  {__('Expo Riva Schuh e Gardabags, Hospitality, Expo Riva Caccia Pesca Ambiente e Trentino Sport Days. La posizione dei nostri appartamenti ti permetterà di raggiungere comodamente i luoghi di lavoro ma di poter godere il nostro territorio nei momenti di pausa.')}</p>

                  <p><strong>{__('Vivere il lago di Garda ed il Monte Baldo tutto l’anno è un grande privilegio')}</strong> {__('Soprattutto nei periodi “fuori stagione” d’autunno ed inverno dove l’atmosfera che si respira è surreale con cielo limpido, lago calmo, clima mite, silenzio e tranquillità.')} <strong>{__('Se hai la possibilità raggiungici in questo periodo per rigenerarti al meglio e fare il pieno di emozioni!')}</strong></p>

                  <p><strong>{__('Si! …il nostro territorio offre tutto questo ed tanto altro ancora che potrai scoprire con l’aiuto di Michela, Monica e Debora quando ci raggiungerai.')}</strong></p>

                </div>

                <div className={[styles.titolo, 'title-color', 'margin_bottom_25'].join(' ')}>
                  <h2>{__('TI ASPETTIAMO PRESTO!')}</h2>
                </div>


              </div>

            </div>
          </main>
        </Fragment>
    )
  }
}