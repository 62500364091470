import {
  noEmpty,
  validateEmail,
  privacy,
  select,
  // telephone
} from './../../utils/validation';

export const fieldsBooking = [{
  type: 'text',
  name: 'nome',
  label: 'Nome',
  validation: noEmpty
}, {
  type: 'text',
  name: 'cognome',
  label: 'Cognome',
  validation: noEmpty
}, {
  type: 'text',
  name: 'telefono',
  label: 'Telefono',
  //validation: telephone          // potrebbe essere richiesta validazione in base a configurazioni [ prenotazione || informazioni ]
}, {
  type: 'text',
  name: 'indirizzo',
  label: 'Indirizzo'
}, {
  type: 'text',
  name: 'citta',
  label: 'Citta'
}, {
  type: 'text',
  name: 'cap',
  label: 'CAP'
}, {
  type: 'text',
  name: 'provincia',
  label: 'Provincia'
}, {
  type: 'text',
  name: 'Nazione',
  label: 'Nazione'
}, {
  type: 'email',
  name: 'email',
  label: 'Email',
  validation: validateEmail
}, {
  type: 'email',
  name: 'confirm_email',
  label: 'Ripeti Email',
  validation: validateEmail
}, {
  type: 'textarea',
  name: 'note',
  label: 'Note',
  // validation: noEmpty          // potrebbe essere richiesta validazione in base a configurazioni [ prenotazione || informazioni ]
}];

export const fieldsInformation = [{
  type: 'text',
  name: 'nome',
  label: 'Nome',
  validation: noEmpty
}, {
  type: 'text',
  name: 'cognome',
  label: 'Cognome',
  validation: noEmpty
}, {
  type: 'text',
  name: 'telefono',
  label: 'Telefono',
  //validation: telephone          // potrebbe essere richiesta validazione in base a configurazioni [ prenotazione || informazioni ]
}, {
  type: 'text',
  name: 'indirizzo',
  label: 'Indirizzo'
}, {
  type: 'text',
  name: 'citta',
  label: 'Citta'
}, {
  type: 'text',
  name: 'cap',
  label: 'CAP'
}, {
  type: 'text',
  name: 'provincia',
  label: 'Provincia'
}, {
  type: 'text',
  name: 'nazione',
  label: 'Nazione'
}, {
  type: 'email',
  name: 'email',
  label: 'Email',
  validation: validateEmail
}, {
  type: 'email',
  name: 'confirm_email',
  label: 'Ripeti Email',
  validation: validateEmail
}, {
  type: 'textarea',
  name: 'note',
  label: 'Note',
  // validation: noEmpty          // potrebbe essere richiesta validazione in base a configurazioni [ prenotazione || informazioni ]
}];

export const privacy_fieldsBooking = [{
  type: 'checkbox',
  name: 'prenotazione',
  label: 'Ho letto l\'informativa sulla privacy',
  validation: privacy
}, {
  type: 'checkbox',                              // "regole"  presente solo nel caso si tratti di una prenotazione  (e non di una richiesta informazioni)
  name: 'regole',
  label: 'Ho letto e accetto il regolamento della struttura',
  validation: privacy
}, {
  type: 'checkbox',
  name: 'newsletter_booking',
  label: 'Ho preso visione dell’informativa privacy e do\' consenso al trattamento dei miei dati per l\'invio della newsletter ',
  // validation: privacy
}];

export const privacy_fieldsInformation = [{
  type: 'checkbox',
  name: 'informazioni',
  label: 'Ho letto l\'informativa sulla privacy',
  validation: privacy
}, {
  type: 'checkbox',
  name: 'newsletter_info',
  label: 'Ho preso visione dell’informativa privacy e do\' consenso al trattamento dei miei dati per l\'invio della newsletter ',
  // validation: privacy
}];

export const creditCard_fields = [ {
  type: 'text',
  name: 'card_number',
  label: 'Numero di carta',
  validation: noEmpty
}, {
  type: 'radio',
  name: 'tipo_carta',
  label: 'Tipo di carta',
  options: {
    0: 'Visa',
    1: 'Mastercard'
  },
  class: 'w_50',
  validation: noEmpty
  // validation: privacy
},{
  type: 'text',
  name: 'intestatario',
  label: 'Intestatario',
  validation: noEmpty
}, {
  type: 'select',
  name: 'scadenza',
  label: 'Scadenza',
  options: {
    0: 'Mese',
    1: 'Anno'
  },
  class: 'w_33',
  validation: select
}, {
  type: 'text',
  name: 'cvv',
  label: 'CVV',
  class: 'w_25',
  validation: noEmpty
}];