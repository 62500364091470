import L from 'leaflet';

const markerIcon = new L.Icon({
    iconUrl: require('./img/markerIcon.png'),
    iconRetinaUrl: require('./img/markerIcon.png'),
    iconSize:     new L.Point(32, 50),
    iconAnchor:   new L.Point(16, 48), // point of the icon which will correspond to marker's location
    popupAnchor:  new L.Point(0.5, -45)  // point from which the popup should open relative to the iconAnchor
});

export { markerIcon };