import React, {PureComponent} from 'react';
import {DateRangePicker, DayPickerRangeController} from 'react-dates';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import moment from 'moment';
import "moment/min/locales";

import _ from 'lodash';

import {__} from '../../utils/translator';
import style from './style.module.css';
import Appartamenti from './../../services/appartamenti';
import {parseQuery} from './../../utils';

const rightArrow =
  <svg className="DayPickerNavigation_svg__horizontal DayPickerNavigation_svg__horizontal_1" focusable="false" viewBox="0 0 1000 1000">
    <path d="M694.4 242.4l249.1 249.1c11 11 11 21 0 32L694.4 772.7c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210.1-210.1H67.1c-13 0-23-10-23-23s10-23 23-23h805.4L662.4 274.5c-21-21.1 11-53.1 32-32.1z"></path>
  </svg>;
const leftArrow =
  <svg className="DayPickerNavigation_svg__horizontal DayPickerNavigation_svg__horizontal_1" focusable="false" viewBox="0 0 1000 1000">
    <path d="M336.2 274.5l-210.1 210h805.4c13 0 23 10 23 23s-10 23-23 23H126.1l210.1 210.1c11 11 11 21 0 32-5 5-10 7-16 7s-11-2-16-7l-249.1-249c-11-11-11-21 0-32l249.1-249.1c21-21.1 53 10.9 32 32z"></path>
  </svg>

export default class Calendario extends PureComponent {

  constructor(props) {
    super(props);

    let startDate = null;
    let endDate = null;
    if (!props.startDate && window.location.search && props.position !== 'middle') {
      const query = parseQuery(window.location.search) || {};
      startDate = query.startDate ? moment(query.startDate) : null;
      endDate = query.endDate ? moment(query.endDate) : null;
    }

    const state = {
      toggle: true,
      labels: true,
      numOfMonths: 2,
      minimumNights: 1,
      keepOpenOnDateSelect: true,
      showUpdatePrice: false,
      startDate: startDate,
      endDate: endDate,
      currentMonth: 0,
      initialMonth: props.startDate ? moment(props.startDate) : moment(),
      ...props
    };

    // console.log('props.startDate', props.position, state.initialMonth, window.location);

    if (props.position === 'middle') {
      state.focusedInput = 'startDate';
    }

    this.state = state;

    this.oggi = moment();
    this.setRef = this.setRef.bind(this);
    this.getCalendar = this.getCalendar.bind(this);
    this.updateDates = this.updateDates.bind(this);
    this.isDayBlocked = this.isDayBlocked.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.isOutsideRange = this.isOutsideRange.bind(this);
    this.getRestriction = this.getRestriction.bind(this);
    this.handleMonthNumber = this.handleMonthNumber.bind(this);
    this.appartamenti = new Appartamenti();
  }

  setRef(node) {
    this.ref = node;
  }

  componentDidMount() {

    moment.locale(this.state.esercizio.lang);

    this.handleMonthNumber();
    this.getRestriction(this.state.initialMonth || moment(), 'componentDidMount');

    document.addEventListener('click', this.handleClick, false);
    window.addEventListener('resize', this.handleMonthNumber);

  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.startDate) {
      prevProps.startDate = null;
    }
    if (!prevProps.endDate) {
      prevProps.endDate = null;
    }
    if (
      (prevProps.startDate && this.props.startDate && prevProps.startDate.format('YYYY-MM-DD') !== this.props.startDate.format('YYYY-MM-DD'))
      ||
      (prevProps.endDate && this.props.endDate && prevProps.endDate.format('YYYY-MM-DD') !== this.props.endDate.format('YYYY-MM-DD'))
    ) {
      let {startDate, endDate} = this.props;

      if (startDate && startDate._isValid === false) {
        startDate = null;
      }
      if ((endDate && endDate._isValid === false) || (endDate && this.state.focusedInput === 'endDate')) {
        endDate = null;
      }

      if (this.state.position === 'bottom') {
        if (!startDate) {
          this.setState({focusedInput: 'startDate'});
        } else if (endDate && this.state.focusedInput === 'startDate') {
          this.setState({focusedInput: null});
          document.getElementById(this.ref.id).getElementsByClassName('DateInput_input')[0].blur();
        }
      }

      // if(endDate && this.state.focusedInput === 'endDate'){
      //   this.setState({
      //     endDate: null,
      //   });
      // } else {
      this.setState({startDate, endDate});
      // }

      this.getRestriction(startDate || moment(), 'componentDidUpdate');
    }

    const prevCalr = Object.keys(prevState.calendar || {});
    const currentCal = Object.keys(this.state.calendar || {});
    if ((prevCalr && prevCalr.length) !== (currentCal && currentCal.length)) {
      this.setState({calendar: this.state.calendar});
    }

    return false;
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
    window.removeEventListener('resize', this.handleMonthNumber);
  }

  handleMonthNumber = () => {
    const {numOfMonths} = this.state;
    if (window.innerWidth <= 680 && numOfMonths === 2) {
      this.setState({numOfMonths: 1})
    } else if (window.innerWidth > 680 && numOfMonths === 1) {
      this.setState({numOfMonths: 2})
    }
  }

  normalizeNumber(number) {
    if (number < 11) {
      return `0${number}`;
    }
    return number;
  }

  getRestriction(startDate, direction) {

    const {
      esercizio,
      calendar,
      position,
      match,
    } = this.state;
    let {currentMonth} = this.state;
    const self = this;

    const from = startDate.clone();
    const to = startDate.clone().add(1, 'months');

    if (direction === 'back') {
      currentMonth--;
    } else if (direction === 'forward') {
      currentMonth++;
    }

    const fromFormatted = from.format('YYYY-MM-DD');
    const toFormatted = to.format('YYYY-MM-DD');
    // console.log('startDate', startDate.format('YYYY-MM-DD'), position, direction, 'fromFormatted', fromFormatted, 'toFormatted', toFormatted);

    this.setState({currentMonth});

    const detail = position === 'top' ? '' : match.params.detail;
    if (!esercizio || !match || !match.params) return;
    if (calendar && calendar[fromFormatted] && calendar[toFormatted]) return;
    if (to - from === 1) return;
    if (to.isBefore(this.oggi)) return;

    const max_MinStay = _.get(esercizio, 'configurazioni.minStay_massimo', 7);

    this.setState({isLoading: true});
    this.appartamenti.getCalendar(
      esercizio.id,
      from.startOf('month').format('YYYY-MM-DD'),
      to.endOf('month').add(max_MinStay, 'days').format('YYYY-MM-DD'),
      detail
    ).then(({data}) => {
        self.setState({
          isLoading: false,
          calendar: Object.assign({}, calendar, data)
        });
        // console.log('from', from.startOf('month').format('YYYY-MM-DD'), 'da', to.endOf('month').add(max_MinStay, 'days').format('YYYY-MM-DD'));
        // console.log('this.state.calendar', this.state.calendar);
      })
      .catch(error => console.log('error', error));
  }

  // Controllo se ho selezionato un renge con all'interno una data prenotata
  isOutsideRange(date) {
    const giorno = date.format('YYYY-MM-DD');
    const {startDate, endDate, calendar} = this.state;

    // Se questo giorno é interno al renge e se questa data non esiste nel calendario e se:   (anche solo una delle seguenti)
    // - questo giorno è = startDate & il suo checkIn è falso
    // - questo giorno è = endDate & il suo checkOut è falso
    // - questo giorno non è nè startDate nè endDate & la sua dispo è falsa
    // allora non posso prenotare
    if (startDate < date && date < endDate && calendar[giorno] && ((startDate && giorno === startDate.format('YYYY-MM-DD') && calendar[giorno].checkIn === false) || (endDate && giorno === endDate.format('YYYY-MM-DD') && calendar[giorno].checkOut === false) || (endDate && startDate && giorno !== endDate.format('YYYY-MM-DD') && giorno !== startDate.format('YYYY-MM-DD') && calendar[giorno].dispo === false))) {

      // console.log(this.state.focusedInput , startDate)

      if (this.state.focusedInput === 'endDate' && startDate) {
        this.props.updateDates({
          startDate: startDate,
          endDate: null,
          focusedInput: 'endDate'
        });
        this.setState({startDate: startDate, endDate: null, focusedInput: 'endDate'});
      } else {
        this.props.updateDates({
          startDate: null,
          endDate: null,
          focusedInput: 'startDate'
        });
        this.setState({startDate: null, endDate: null, focusedInput: 'startDate'});
      }
    }

  }

  onFocusChange(focusedInput) {
    if (this.state.position !== 'top') {
      this.setState({
        focusedInput: !focusedInput ? 'startDate' : focusedInput,
      });
      if (focusedInput === 'endDate') {
        this.setState({
          keepOpenOnDateSelect: false
        });
      }
    } else {
      this.setState({
        focusedInput
      });
    }
  }

  updateDates({startDate, endDate}) {

    const {focusedInput} = this.state;

    // console.log(focusedInput, endDate, startDate)

    if (this.state.position === 'middle') {
      // Attendo il click del "verifica prezzi" - rimosso click, verifica automatica alla selezione dell'endDate
      this.setState({startDate, endDate, showUpdatePrice: true});
      if (startDate && endDate) {
        this.props.updateDates({
          showUpdatePrice: true,
          focusedInput,
          startDate,
          endDate
        });
      }
    } else {
      this.setState({startDate, endDate});
      this.props.updateDates({
        focusedInput,
        startDate,
        endDate
      });
    }

  }

  isDayBlocked(day) {
    // Blocco di default i giorni prima
    if (this.state.esercizio.configurazioni.prenotazione_giornata_finoalle) {
      day.startOf('day').add(this.state.esercizio.configurazioni.prenotazione_giornata_finoalle, 'hours');
    }
    if (day.isBefore(this.oggi)) {
      return true;
    }

    // Se il calendario non e ncora pronto non faccio nessun check
    const {calendar, focusedInput} = this.state;
    if (calendar && Object.keys(calendar) === 0) return true;

    const formattedDay = moment(day).format('YYYY-MM-DD');
    // console.log('isDayBlocked', formattedDay)
    const objectDay = calendar[formattedDay];
    // console.log('focusedInput', focusedInput, 'formattedDay', formattedDay, 'objectDay', objectDay)

    let dispo = objectDay && objectDay.dispo;
    const canCheckIn = objectDay && objectDay.checkIn;
    const checkOut = objectDay && objectDay.checkOut;
    const minStay = objectDay && objectDay.minStay;

    if (focusedInput === 'startDate') {
      this.setState({minimumNights: minStay});
      if (canCheckIn) {
        if (minStay > 1) {
          let i = 1;
          do {
            const objectDay = calendar[moment(day).add(i, 'days').format('YYYY-MM-DD')];
            if (i === minStay) {
              dispo = (objectDay && objectDay.checkOut) ? true : false;
            } else {
              dispo = (objectDay && objectDay.dispo) ? true : false;
            }
            i++;
          } while (dispo === true && i <= minStay)
          // console.log('formattedDay', formattedDay, 'dispo', dispo);
          return !dispo;
        } else {
          return false;
        }
      }
      return true;
    } else {
      if (this.state.startDate <= day && checkOut) {
        return false;
      }
      return true;
    }
  }

  getCalendar(position) {

    const {startDate, endDate, openDirection, numOfMonths, isLoading, esercizio} = this.state;

    // Proprieta comuni
    let calendarProps = {
      hideKeyboardShortcutsPanel: true,
      endDate: endDate,
      startDate: startDate,
      numberOfMonths: numOfMonths,
      // keepOpenOnDateSelect: false,
      onFocusChange: this.onFocusChange,
      focusedInput: this.state.focusedInput,
      onPrevMonthClick: (day) => this.getRestriction(day, 'back'),
      onNextMonthClick: (day) => this.getRestriction(day, 'forward'),
      renderMonthElement: ({month}) => {
        return moment(month).locale(esercizio.lang).format('MMMM YYYY');
      }
    };

    if (isLoading === true) {
      calendarProps = {
        ...calendarProps,
        navPrev: <div style={{
          pointerEvents: 'none',
          opacity: '0.6'
        }} className='DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5'>{leftArrow}</div>,
        navNext: <div style={{
          pointerEvents: 'none',
          opacity: '0.6'
        }} className='DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5'>{rightArrow}</div>
      };
    }

    // Da applicare solo al calendario nella pagina dettaglio
    if (position !== 'middle') {
      calendarProps = {
        ...calendarProps,
        noBorder: true,
        endDateId: "endDate",
        startDateId: 'startDate',
        displayFormat: 'DD/MM/YYYY',
        minimumNights: this.state.minimumNights,
        endDatePlaceholderText: __('DD/MM/YYYY'),
        startDatePlaceholderText: __('DD/MM/YYYY'),
        openDirection: openDirection || 'down',
        customArrowIcon: <div className={style.separatore}></div>
      }
    }

    if (position !== 'middle') {
      calendarProps = {
        ...calendarProps,
        readOnly: true,
        initialVisibleMonth: (aa) => {
          const {startDate, currentMonth, initialMonth} = this.state;

          if (startDate) {
            // console.log(startDate.format('MM'), initialMonth.clone().add(currentMonth, 'months').format('MM'));
            if (startDate.format('MM') !== initialMonth.clone().add(currentMonth, 'months').format('MM')) {
              // console.log(startDate.clone().add(-1, 'months'))
              return startDate.clone().add(-1, 'months');
            }
            // console.log(startDate)
            return startDate;
          }
          // console.log('initialMonth',initialMonth)
          return initialMonth.clone().add(currentMonth, 'months');
        }
      }
    }

    // Controllo i giorni bloccati solo una volta che ho fatto il fetch dei dati
    if (this.state.calendar && position !== 'top') {
      calendarProps = {
        ...calendarProps,
        minimumNights: this.state.minimumNights,
        isOutsideRange: this.isOutsideRange,
      }
    }

    if (this.state.calendar) {
      calendarProps = {
        ...calendarProps,
        isDayBlocked: (day) => {
          let {initialMonth, currentMonth} = this.state;
          let fromMonth = parseInt(initialMonth.clone().add(currentMonth, 'months').format('YMM'));
          let toMonth = parseInt(initialMonth.clone().add(currentMonth + 1, 'months').format('YMM'));
          let dayMonth = parseInt(moment(day).format('YMM'));

          if (dayMonth >= fromMonth && dayMonth <= toMonth) {
            return this.isDayBlocked(day);
          } else {
            return true
          }
        }
      }
    }
    // console.log('position', position, 'calendarProps', calendarProps)

    moment.locale(esercizio.lang);
    switch (position) {
      case 'bottom':
        return <DateRangePicker {...calendarProps}
                                key='c_bottom'
                                onDatesChange={this.updateDates}
                                keepOpenOnDateSelect={this.state.keepOpenOnDateSelect}/>
      case 'middle':
        const {calendar, focusedInput} = this.state;

        return <DayPickerRangeController {...calendarProps}
                                         key='c_middle'
                                         onDatesChange={this.updateDates}
                                         renderDayContents={day => {

                                           if (!calendar || Object.keys(calendar) === 0)
                                             return (<span>{moment(day).format('D')}</span>)

                                           const formattedDay = moment(day).format('YYYY-MM-DD');
                                           const objectDay = calendar[formattedDay];


                                           let dispo = objectDay && objectDay.dispo;
                                           if (!dispo) {
                                             return (
                                               <span className={`noDispo`}>{moment(day).format('D')}</span>);
                                           }
                                           const canCheckIn = objectDay && objectDay.checkIn;
                                           const checkOut = objectDay && objectDay.checkOut;
                                           const minStay = objectDay && objectDay.minStay;
                                           //console.log(formattedDay,'dispo',dispo,'canCheckIn',canCheckIn)
                                           if (focusedInput === 'startDate') {
                                             if (checkOut) {
                                               if (minStay > 1) {
                                                 let i = 1;
                                                 do {
                                                   const objectDay = calendar[moment(day).add(i, 'days').format('YYYY-MM-DD')];
                                                   if (i === minStay) {
                                                     dispo = (objectDay && objectDay.checkOut) ? true : false;
                                                   } else {
                                                     dispo = (objectDay && objectDay.dispo) ? true : false;
                                                   }
                                                   i++;
                                                 } while (dispo === true && i <= minStay)
                                                 if (!dispo) {
                                                   return (
                                                     <span className={`onlyCheckout`}>{moment(day).format('D')}</span>);
                                                 }
                                               }
                                             }
                                           }
                                           return (<span>{moment(day).format('D')}</span>)

                                         }}
                                         keepOpenOnDateSelect={this.state.keepOpenOnDateSelect}/>
      default:
        return <DateRangePicker  {...calendarProps}
                                 key='c_top'
                                 onDatesChange={this.updateDates}/>
    }
  }

  render() {
    return (
      <div ref={this.setRef} id={`calendar_${this.state.position}`} className={style.calendarioWrapper}>
        {this.state.labels && <div className={style.labels}>
          <div className={[style.label, 'title-color'].join(' ')}>{__('Arrivo:')}</div>
          <div className={[style.label, 'title-color'].join(' ')}>{__('Partenza:')}</div>
        </div>}
        <div className={style.calendario}>
          {this.getCalendar(this.props.position)}
        </div>
      </div>
    )
  }
}