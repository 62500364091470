import React, { Component } from 'react';

export default class Layout extends Component {

  constructor(props){
    super(props)
    this.state = {
      brackpoint: ''
    }
    window.brackpoint = '';
    window.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    window.imgContainer = this.imgContainer.bind(this);
    window.goto = this.goto.bind(this);
    window.getBrPoint = this.getBrPoint.bind(this);
  }

  componentDidMount() {

    const ua = navigator.userAgent.toLowerCase(); 
    if (ua.indexOf('safari') != -1 && ua.indexOf('chrome') <= -1) {
      document.body.classList += 'isSafari';
    }

    window.addEventListener('resize', this.handleWindowSizeChange);
    window.handleWindowSizeChange();
    
    window.addEventListener('build', this.imgContainer);
    window.addEventListener('load', this.anchorScroll);
    window.imgContainer();
    setTimeout(function () {
      window.imgContainer();
      setTimeout(function () {
        window.imgContainer();
        setTimeout(function () {
          window.imgContainer();
        }, 1000);
      }, 1000);
    }, 2000);

    if("ontouchstart" in window){
      document.body.classList += " touch";
    }
  }

  // Togliamo il listener quando il componente viene "smontato"
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
    window.removeEventListener('build', this.imgContainer);
    window.removeEventListener('load', this.anchorScroll);
  }


  goto = (element, to, duration) => {
    if (duration <= 0) return;
    var difference = to - element.scrollTop;
    var perTick = difference / duration * 10;
    
    setTimeout(function() {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop === to) return;
        this.goto(element, to, duration - 10);
    }, 10);

  }

  anchorScroll = () => {
    if(window.location.hash) {
      const elemId = window.location.hash.replace('#','');
      setTimeout(function () {
        let element = document.getElementById(elemId);
        if (!element && elemId == 'prenota') {
          element = document.getElementById('calendar');
        }
        if (element) {
          window.goto(document.body, element.offsetTop, 600);
        }
      }, 1500);
    }
  }

  imgContainer = () => {
    
      const imgElements = document.getElementsByClassName('img-container');

      Array.prototype.filter.call(imgElements, function(imgEl){      
        var th = imgEl.clientHeight,//box height
            tw = imgEl.clientWidth,//box width
            im = imgEl.getElementsByTagName('img')[0];//image
          
            if(im){
              var ih = im.height,//inital image height
                  iw = im.width;//initial image width
              
              if (ih > iw) {//if portrait
                  // im.addClass('ww').removeClass('wh');//set width 100%
                  if(im.classList !== 'ww')
                    im.classList = 'ww';

                  let eim = imgEl.getElementsByTagName('img')[0]; //image
                  let eih = eim.height; //inital image height
                  // eiw = eim.width;//initial image width

                  if (eih < th) {
                    if(eim.classList !== 'wh')
                      eim.classList = 'wh';
                      // im.addClass('wh').removeClass('ww');//set height 100%
                  } else {
                    if(eim.classList !== 'ww')
                      eim.classList = 'ww';
                      // im.addClass('ww').removeClass('wh');//set width 100%
                  }
              } else {//if landscape
                  if(im.classList !== 'wh')
                    im.classList = 'wh';
                  // im.addClass('wh').removeClass('ww');//set height 100%

                  let eim = imgEl.getElementsByTagName('img')[0],//image
                  //eih = eim.height,//inital image height
                  eiw = eim.width;//initial image width

                  if (eiw < tw) {
                    if(eim.classList !== 'ww')
                      eim.classList = 'ww';
                      // im.addClass('ww').removeClass('wh');//set width 100%
                  } else {
                    if(eim.classList !== 'wh')
                      eim.classList = 'wh';
                      // im.addClass('wh').removeClass('ww');//set height 100%
                  }
              }
            }
      });
  }

  getBrPoint = () => {
    let brackpoint = 'xlg'
    if (window.innerWidth <= 1280) {
      brackpoint = 'lg'
    }
    if (window.innerWidth <= 1023) {
      brackpoint = 'md'
    }
    if (window.innerWidth <= 768) {
      brackpoint = 'sm'
    }
    if (window.innerWidth <= 650) {
      brackpoint = 'xs'
    };
    if (window.innerWidth <= 480) {
      brackpoint = 'xxs'
    };
    if(window.brackpoint !== brackpoint) {
      window.brackpoint = brackpoint;
      this.setState({ brackpoint: brackpoint });
    }
  }

  handleWindowSizeChange = () => {
    
    window.getBrPoint();

    if (this.resizeTO) clearTimeout(this.resizeTO);
    this.resizeTO = setTimeout(function () {
      window.imgContainer();
    }, 100);
  };

  render(){
    const { brackpoint } = this.state;
    let clonedElementWithMoreProps = [];
    Object.values(this.props.children).map((element,indx) => {
      if(indx === 0){
        clonedElementWithMoreProps.push( <element.type key={indx} brackpoint={brackpoint} {...element.props}></element.type> );
      } else {
        clonedElementWithMoreProps.push( <element.type key={indx} {...element.props}></element.type> );
      }
    });
    return clonedElementWithMoreProps;
  }

}