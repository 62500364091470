import React, { Component,Fragment } from 'react';
import { __ } from '../../utils/translator';
import style from './style.module.css';
import SearchBar from '../SearchBar';

export default class BookingError extends Component {

  constructor(props){
    super(props);
    this.state = {
      ...props
    }
  }  

  render() {    

    const { esercizio } = this.state;

    if(!esercizio) return <div className={['align_center',style.error,style.centerColumn].join(' ')}>
        <div className={'text'} >
            <h1>{this.props.text.h1}</h1>
            <p><a href={window.basename} >{this.props.text.toHome}</a></p>
            
        </div>
      </div>;

    return (
      <Fragment>
        <SearchBar 
          position='top'
          search={false}
          key='search_top'
          esercizio={esercizio} 
          match={this.props.match}
          history={this.props.history}
          location={this.props.location} />
        <div className={['align_center',style.error,style.centerColumn].join(' ')}>
          <div className={'text'} >
              <h1>{this.props.text.h1}</h1>
              <p>{this.props.text.newSearch} <a href={window.basename} >{this.props.text.toHome}</a></p>
              
          </div>
        </div>
      </Fragment>
    )

  }

}