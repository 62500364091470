import React, { Component, Fragment } from "react";
import Appartamenti from './../../services/appartamenti';
import style from './style.module.css';
import { __ } from "../../utils/translator";
import _ from 'lodash';

export default class PaymentMethod extends Component{


  constructor(props) {
    super(props);
    this.state = {
      active: false,
      ...props
    };

    this.onClick = this.onClick.bind(this);
    this.getInfoItem = this.getInfoItem.bind(this);
    this.appartamenti = new Appartamenti();
  }

  componentDidMount(){
    this.loadData()
  }

  async componentDidUpdate(prevProps, prevState){
    const { query } = this.props;
    
    if (prevProps.query.startDate !== query.startDate || prevProps.query.endDate !== query.endDate || prevProps.item != this.props.item) {
      this.loadData()
    }
  }

  loadData = async () => {
    const { query, esercizio } = this.state;
    
    const { pagamento, prezzo, offerte } = this.props.item;
    const idPagamento = pagamento.id;

    this.offerte_estese = await this.getInfoOfferte(esercizio, offerte);
    this.getInfoItem(esercizio, query.startDate, query.endDate, prezzo, idPagamento);  
  }

  getInfoOfferte = async (esercizio, offerte) => {

    let offerte_estese = {
			positive: [],
			negative: [],
			descrittive: [],
		};

    if(offerte && Object.values(offerte)) {
      const res = await Promise.all(
        Object.values(offerte).map(async (val, indx) => {
          const href = _.get(val,'_links.offerta.href', '');
          if(href !== '') {
            try {
              const { data } = await this.appartamenti.getInfoHref(esercizio, href)
              if(val.tipo == "+") {
                offerte_estese.positive.push(data)
              } else if(val.tipo == "-") {
                offerte_estese.negative.push(data)
              } else {
                offerte_estese.descrittive.push(data)
              }
            } catch (error) {
              console.log('error', error)
            }
          }
        })
      )

      this.setState({
        info_offerte: offerte_estese
      });
    }
    return offerte_estese
  }

  onClick(){
    const { query, esercizio, info, frasiPenali, info_offerte = false} = this.state;

    const { pagamento, prezzo, offerte } = this.props.item;
    const idPagamento = pagamento.id;

    if (info && frasiPenali) {

      return this.props.onClick({
        info,
        frasiPenali,
        activePayment: idPagamento,
        retta: this.props.item,
        info_offerte
      });

    } else {
      this.getInfoItem(esercizio, query.startDate, query.endDate, prezzo, idPagamento);

    }
  }

  getInfoItem(esercizio, startDate, endDate, prezzo, idPagamento){
    const self = this;
    this.appartamenti.getInfoPagamento(esercizio, startDate, endDate, prezzo, idPagamento)
      .then(res => {
        const { data: { frasi_pagamento, frasi_penali } } = res;
        if (_.get(frasi_pagamento ,'extra.text','') !== '' && _.get(frasi_pagamento ,'frase.text','') !== ''){
          frasi_pagamento.frase.text += '<br>'+ frasi_pagamento.extra.text;
        }
        self.setState({
          titolo: frasi_pagamento.frase_mini.text, 
          tipo: frasi_pagamento.nome.text, 
          info: frasi_pagamento.frase, 
          frasiPenali: frasi_penali,
          activePayment: idPagamento
        })
        self.props.onLoad && self.props.onLoad(idPagamento, {
          info: frasi_pagamento.frase,
          frasiPenali: frasi_penali,
          activePayment: idPagamento,
          retta: self.props.item,
          info_offerte: this.offerte_estese,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }
  
  render (){
    
    const { active } = this.props;
    const { className, titolo, tipo } = this.state;
    const { prezzo, prezzo_base } = this.props.item;
    return (
      <div onClick={this.onClick} className={[ style.method, className, active ? style.active : ''].join(' ')}>
        <h3 className={style.titolo}><span dangerouslySetInnerHTML={{__html: titolo !== '' ? titolo : __('Prenota ora')}}></span><span className={style.light}>{__('mezzo:')} </span><span>{tipo}</span></h3>
        <p className={style.prezzo}>
          {(prezzo_base > prezzo) ? 
            <Fragment>
              <span className={style.offerta} >{__('Offerta')}</span>
              <sup className={style.prezzo_base} >€ {parseFloat(prezzo_base).toFixed(2)}</sup> 
            </Fragment> : ''} 
          <span className={['nowrap',style.prezzo_intero].join(' ')} >€ {parseFloat(prezzo).toFixed(2)}</span>
        </p>
        <div className={style.button}>
          <div className={['tertiary-background', style.inner].join(' ')}>
          </div>
        </div>
      </div>
    )
  }
}

