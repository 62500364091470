import React from "react";
import style from './style.module.css';

export default class BannerContatti extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      ...props
    }
    
  }
  
  render (){
    
    const { image, title_h1, title_h2 } = this.state;
    
    return (
      <div className={[style.bannerContatti,'w-100','img-container'].join(' ')}>
        {/* <div className={[''].join(' ')}> */}
        {image && <img src={image} className={'ww'} />} 
        {/* </div> */}
        
        {title_h1 && <h1>{title_h1}</h1>}
        {title_h2 && <h2>{title_h2}</h2>}
        
      </div>
    )
  }
}
