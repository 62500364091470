import React, { Component, Fragment } from 'react';
import 'swiper/dist/css/swiper.css'
import Swiper from 'swiper/dist/js/swiper.esm.bundle';
import style from './style.module.css';
import _ from 'lodash';
import Lightbox from 'react-images';

export default class Gallery extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ...props,
      virtualData: {
        slides: []
      },
      realSlides: [],
      slides: [],
      currentImage: 0,
      srcSlides: []
    }

    // this.getRealSlide = this.getRealSlide.bind(this);
    this.getVirtualSlide = this.getVirtualSlide.bind(this);
    
    this.closeLightbox = this.closeLightbox.bind(this);
		this.gotoNext = this.gotoNext.bind(this);
		this.gotoPrevious = this.gotoPrevious.bind(this);
		this.gotoImage = this.gotoImage.bind(this);
		this.handleClickImage = this.handleClickImage.bind(this);
		this.openLightbox = this.openLightbox.bind(this);
	}

    openLightbox(index, event) {
			event.preventDefault();
			this.setState({
				currentImage: parseInt(index),
				lightboxIsOpen: true
			});
    }
    closeLightbox() {
			this.setState({
				currentImage: 0,
				lightboxIsOpen: false
			});
    }
    gotoPrevious() {
			this.setState({
				currentImage: this.state.currentImage - 1
			});
    }
    gotoNext() {
			this.setState({
				currentImage: this.state.currentImage + 1
			});
    }
    gotoImage(index) {
			this.setState({
				currentImage: index
			});
		}
    handleClickImage() {
        if (this.state.currentImage === this.props.images.length - 1) return;

        this.gotoNext();
    }

  static getDerivedStateFromProps(nextProps, prevState){
    if (nextProps.images !== prevState.images){
      return {
        ...nextProps
      };
   }
   else return null;
 }

  componentDidMount() {

    const self = this;
    let slides = [];

    
    const { options, autoInit = false } = this.props;
    let newOptions = {};

    if(options.slidesPerView === 'auto'){
      slides = this.state.images; //this.getRealSlide();
      newOptions = {
        // init: false,
        initialSlide: 0,
        preloadImages: true,
        ...options
      };
      this.setState({realSlides: slides, options: newOptions });
    } else {
      slides = this.getVirtualSlide();

      if(!slides) return null;
      newOptions = {
        init: autoInit,
        preloadImages: true,
        updateOnImagesReady: true,
        virtual: {
          slides: slides,
          renderExternal(data) {
            self.setState({
              virtualData: data,
            });
          }
        },
        on: {
          init: function () {
              // window.imgContainer();
          },
          imagesReady: function () {
            // if (this.timeoutimg) clearTimeout(this.timeoutimg);
            // this.timeoutimg = setTimeout(function () {
            //   window.imgContainer();
            // }, 300);
          },
          slideChangeTransitionStart: function () {
            // window.imgContainer();
          },
        },
        ...options
      };
    }
    
    if(!slides) return null;
    
    const swiper = new Swiper(`.swiper-container-${this.props.id}`, {
      ...newOptions
    });

    if(!autoInit) {
      setTimeout(() => swiper.init());
    }
    
    if(options && (options.slidesPerView > 1 || options.slidesPerView === 'auto')) {
      const srcs = this.getSrc();
      self.setState({srcSlides: srcs})
    }

    
  }

  // /**
  //  * recupera le slide per comporre lo swiper con slide reali 
  //  */
  // getRealSlide(){    
  //   const { images } = this.state;
  //   let slides = [];
  //   images.forEach((image, index) => {
  //     slides.push(<img key={index} slideindex={index} src={`/api/appartamenti/image/${image.id}`} className={'wh'} />);
  //   });
  //   return slides;
  // }
  /**
   * recupera le slide per comporre lo swiper con virtual slides (lista)
   */
  getVirtualSlide(){    
    const { images } = this.state;
    let slides = [];
    images.forEach((image, index) => {
      !image.pano &&
        slides.push(<div className={[style.imageSlide,'imgcontain'].join(' ')} >
          <div className={style.bg_image} style={{backgroundImage: 'url('+image.src+'?h=250)'}}></div>
          <img key={index} slideindex={index} src={`${image.src}?h=250`} className={'wh'} />
        </div>);
    });

    /** sono nella lista ne stampo solo 4 */
    return slides.slice(0, 4);
  }
  /**
   * genera l'oggetto di src per popolare il lightbox - non viene chiamato se slidesPerView = 1 (quindi nella lista)
   */
  getSrc(){    
    const { images } = this.state
    let slides = [];
    images.forEach((image, index) => {
      const src = { 'src' : `${image.src}`};
      slides.push(src);
    });
    return slides;
  }

  render() {

    const { images, virtualData, paginationColor, paginationAlign, id, options, srcSlides, currentImage, lightboxIsOpen, realSlides } = this.state;

    if (!images) return <div className={style.spacer}></div>;
    const hasLightbox = (srcSlides && srcSlides.length > 0) ? true : false;

    return (
      <div ref={(node) => { if (node) this.swiper = node.swiper; }} className={[`swiper-container galleryBooking swiper-container-${this.state.id} ${style['swiper-container']}`, style.spacerbefore].join(' ')} >
        <div className="swiper-wrapper">
          {virtualData.slides.length > 0 && virtualData.slides.map((slide, index) => (
            <div className={['swiper-slide', hasLightbox ? 'c_pointer' : ''].join(' ')}
              key={index}
              style={{left: `${virtualData.offset}px`}}
              onClick={(event) => {
                if (hasLightbox) 
                  this.openLightbox(_.get(slide,'props.children.slideindex',0),event)
              }}
            >{slide}</div>
          ))}
          {(_.get(options,'slidesPerView','' === 'auto')) && images.map((slide, index) => (
            !slide.pano && 
              <div className={['swiper-slide', style.w_auto, (hasLightbox) ? 'c_pointer' : ''].join(' ')}
                key={index}
                style={{left: `${virtualData.offset}px`}}
                onClick={(event) => {
                  if (hasLightbox) 
                    this.openLightbox(index,event)
                }}
              >
                <img key={index} slideindex={index} src={`${slide.src}?h=350`} className={'wh'} />
              </div>
          ))}
        </div>
        {hasLightbox &&
        <Lightbox 
          currentImage={currentImage}
          images={srcSlides}
          isOpen={lightboxIsOpen}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          onClose={this.closeLightbox}
        />
        }
        {_.get(options,'navigation') && 
          <Fragment>
            <div className={['swiper-button-prev',`swiper-button-prev-${id}`].join(' ')} ></div>
            <div className={['swiper-button-next',`swiper-button-next-${id}`].join(' ')} ></div>
          </Fragment>
        }
        {_.get(options,'pagination') && 
          <div className={['swiper-pagination',`swiper-pagination-${id}`,style[paginationColor],paginationAlign].join(' ')}></div>
        }
      </div>
    )
  }

}