import React, { Component } from 'react';
import style from './style.module.css';
import Popup from './../Popup';
import _ from 'lodash';

export default class Languages extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      popupLingue: false
    }

    this.onTogglePopup = this.onTogglePopup.bind(this);
  }

  componentDidMount() {
    const { langs, currentLang } = this.state;
    
    const langsWithoutCurrent = [...langs];
    var current = _.remove(langsWithoutCurrent, function(n) {
      return n === currentLang;
    });
    this.setState({ langsWithoutCurrent: langsWithoutCurrent});
  }

  onTogglePopup(){
    this.setState({
      popupLingue: !this.state.popupLingue
    });
  }

  render() {   
    const { popupLingue, currentLang, langsWithoutCurrent, langs } = this.state;

    const baseLink = window.basename.slice(0, -2);
    const pathLink = window.location.pathname.replace(window.basename,'');
    const queryString = window.location.search;
    // link cambio lingua da testare su sottodomini custom
    
    
    return (
      <div className={style.languageContainer}>
        {(window.brackpoint === 'xlg' || window.brackpoint === 'lg') &&
        <Popup 
          direction='top' 
          classe='lang'
          toggle={popupLingue}
          onToggle={this.onTogglePopup}
          button={<div onClick={this.onTogglePopup} className={style.currentLang} >{currentLang}</div>} >
          {langsWithoutCurrent && langsWithoutCurrent.map((element,index) => <a key={index} href={baseLink + element + pathLink + queryString } >{element}</a>)}
        </Popup>
        }
        {(window.brackpoint !== 'xlg' && window.brackpoint !== 'lg') &&
          langs && langs.map((element,index) => <a key={index} href={baseLink + element + pathLink + queryString } className={['primary-color',(currentLang === element)? style.currentLangMobile : style.font_300].join(' ')}>{element}</a>)
        }
      </div>
    )
  }

}