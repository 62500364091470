import { MapLayer, withLeaflet } from "react-leaflet";
import L from "leaflet";
import _ from "lodash";

require("leaflet.markercluster");


const isSameBuilding = (items) => {
  for(let i = 0; i < items.length - 1; i++) {
    const coordA = items[i].getLatLng()
    const coordB = items[i + 1].getLatLng()
    //console.log("items isSameBuiolding", items[i].options.data)
    if(coordA.lat != coordB.lat || coordA.lng != coordB.lng) return false
  }
  return true
}

class MarkerClusterGroup extends MapLayer {

  createLeafletElement(props) {
    const el = new L.markerClusterGroup({
      iconCreateFunction:  (cluster) => {
        const childMarkers = cluster.getAllChildMarkers()
        const active = childMarkers.find((marker) => {
          return _.get(marker, "options.icon.options.itemId", "") == this.props.activeItem
        })
        /* if(!this.props.isHome) {
          const sameBuilding = isSameBuilding(childMarkers)
          if(sameBuilding) {
            const minPrice = childMarkers.reduce((acc, item) => {
              const price = _.get(item, "options.data.retta.prezzo", -1)
              if(acc == -1 || price < acc) return price
              return acc
            }, -1)
            return L.divIcon({
              className: active ? "map-price active" : "map-price",
              iconSize: [85, 32],
              html: (minPrice >= 0) ? `<div>Da ${minPrice}€</div>` : `<div>${_.get(childMarkers[0], "options.data.appartamento.residence-nome")}</div>`
            })
          }
        } */
        return L.divIcon({ html: cluster.getChildCount(), iconSize: [40, 40], className: "marker-cluster" + (active ? " active" : "") })
      },
      ...props
    });
    this.contextValue = {
      ...props.leaflet,
      layerContainer: el
    };
    return el;
    
  }

}

export default withLeaflet(MarkerClusterGroup);