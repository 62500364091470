import React from "react";
import { getRightLegal } from './../../utils';
import { __ } from './../../utils/translator'
import style from './style.module.css'; // eslint-disable-line

export default class Footer extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      ...props
    }
  }
  
  render (){
    const { configurazioni, lang, societa } = this.state.esercizio;
  
    if(!configurazioni || !lang) return null;
    
    this.privacyLink = getRightLegal(configurazioni.legals,'privacy',lang);
    this.cookieLink = getRightLegal(configurazioni.legals,'cookie',lang);
    
    const date = new Date().getFullYear();
    
    return (
      <footer className={['primary-background','primary-color'].join(' ')}>
        <div className={'copyright'}>
          © {date} - {__('Partita Iva')} {societa.partita_iva} - Powered by Mercurio Sistemi
        </div>
        <div className={'legals'}>
        
        <a className={'primary-color'} href={`${this.privacyLink}`} rel="noopener noreferrer" target="_blank">{__('Privacy')}</a> | <a className={'primary-color'} href={`${this.cookieLink}`} rel="noopener noreferrer" target="_blank">{__('Cookie')}</a>
           {/* - P.I. {p_iva} */}
        </div>
        
      </footer>
    )
  }
}
