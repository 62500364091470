import React, { Component } from 'react';
import { __ } from './../../utils/translator';
import { getRightLang } from '../../utils';
import style from './style.module.css';
import EsercizioSvc from './../../services/esercizio';
import _ from 'lodash';

export default class ListHeader extends Component {

  constructor(props){
    super(props);
    this.state = {
      ...props
    }
    this.esercizioSvg = new EsercizioSvc();
    // this.getNessunRisultato = this.getNessunRisultato.bind(this);
  }  

  componentDidMount(){
    
    const { configurazioni } = this.state.esercizio;    
    const self = this;
    const tipo = _.get(configurazioni, `nessun_risultato.tipo`, '');
    const parametri = _.get(configurazioni, `nessun_risultato.parametri`, '');

    const { lang } = this.state.esercizio;

    // recupero il messaggio dal rest se il tipo è "rest"
    if (tipo === 'rest') {
      this.esercizioSvg.getAgencyInfo('norisultati')
      .then(function (response) {
        self.setState({ pageContent: response.data[lang].testo });
      })
      .catch(function (error) {
        self.setState({ pageContent: error });
      });
    } else { // senno lo recupero dalle configurazioni
      self.setState({ pageContent: getRightLang(configurazioni, `nessun_risultato.parametri`, '', lang)[0] });
    }

  }
  
  render() {
    const text = this.state.pageContent;
    
    return (
      <div className={['align_center',style.empty].join(' ')}>
        <div className={'text'} contentEditable='false' dangerouslySetInnerHTML={{ __html: text }}></div>
      </div>
    )

  }

}