import React, { Fragment } from "react";
import _, { filter, isArray } from 'lodash';
import { Redirect } from "react-router";
import List from '../../components/List'
import SearchBar from '../../components/SearchBar';
import CardAppartamento from '../../components/CardAppartamento';
import AppartamentiSvc from '../../services/appartamenti';
import styles from './style.module.css';
import {buildQuery, parseQuery, reBuildQueryString} from './../../utils';
import { __ } from './../../utils/translator';
import moment from 'moment';
import SearchMap, { HAS_MAP, MAP_PERCENTAGE } from "../../components/SearchMap";
import Button from "../../components/Button";
import Modal from "react-modal";
import IconButton from "../../components/IconButton";
import Footer from "../../components/Footer";
import { object } from "prop-types";
import { filtersKeys } from "../../components/Filter"

const DEFAULT_LIMIT = 12;
const DEFAULT_CURRENTPAGE = 1;
const DEFAULT_OFFSET = 0;

export default class Search extends React.Component {

  constructor(props){
    super(props);

    const query = parseQuery(props.location.search, this.normalizeParams) || {};
    const queryFilters = Object.entries(query).reduce((acc, [key, values]) => {
      if(filtersKeys.includes(key)) {
        return {
          ...acc,
          [key]: values
        }
      } else {
        return acc
      }
    }, {})

    const selectedFilters = Object.entries(queryFilters).reduce((acc, [key, stringValues]) => {
      const splitted = stringValues.split(",")
      return {
        ...acc,
        [key]: splitted.map((val) => {
          return { value: val }
        })
      }
    }, {})

    this.state = {
      pageLimit: DEFAULT_LIMIT,
      currentPage: DEFAULT_CURRENTPAGE,
      offset: DEFAULT_OFFSET,
      ordineDirezione: 'ASC',
      selectedResidence: null,
      selectedFilters: selectedFilters,
      isPageChanged: false,
      times: 0,
      startDate: query.startDate ? moment(query.startDate) : null,
      endDate: query.endDate ? moment(query.endDate) : null,
      ordineTipo: 'nome',
      ordineSearch: ['nome', 'prezzo', 'miglior_offerta'],
      hideOrdinamento: false,
      puntiMappa: [],
      queryFilters,
      ...props
    }

    this.onSearch = this.onSearch.bind(this);
    this.fetchAppartamenti = this.fetchAppartamenti.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
    this.onSortByChange = this.onSortByChange.bind(this);
    this.onSortDirectionChange = this.onSortDirectionChange.bind(this);
    this.onUpdateLimitChanged = this.onUpdateLimitChanged.bind(this);
    this.appartamentiSvc = new AppartamentiSvc();
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.location.search !== this.props.location.search || prevProps.location.pathname !== this.props.location.pathname) && !this.state.isPageChanged) {
      const { pageLimit, currentPage, ordineTipo, ordineDirezione } = this.state;
      let newCurrPage = parseInt(this.props.match.params.page) || DEFAULT_CURRENTPAGE;
      let newOffset = DEFAULT_OFFSET;
      if (newCurrPage !== currentPage) {
        newOffset = pageLimit * (newCurrPage - 1);
        this.setState({currentPage: newCurrPage, offset: newOffset});
      }
      this.fetchAppartamenti(pageLimit, newOffset, newCurrPage, ordineTipo, ordineDirezione);
    }
  }

  componentDidMount(){

    const { esercizio, ordineDirezione, pageLimit, match, hideOrdinamento, ordineSearch, ordineTipo } = this.state;
    if (esercizio && pageLimit) {
      const hideOrdine = _.get(esercizio,'configurazioni.hideOrdinamentoSearch') ? _.get(esercizio,'configurazioni.hideOrdinamentoSearch') : hideOrdinamento;
      const OrdineSearch = _.get(esercizio,'configurazioni.ordineSearch') ? _.get(esercizio,'configurazioni.ordineSearch') : ordineSearch;
      const OrdineTipo = OrdineSearch[0] ? OrdineSearch[0] : ordineTipo;
      this.setState({
        hideOrdinamento: hideOrdine,
        ordineSearch: OrdineSearch,
        ordineTipo: OrdineTipo
      });

      let page = 1;
      let offset = 0;
      const queryString = reBuildQueryString(window.location.search, this.normalizeParams);
      const re = /^(\d+)$/;
      // senza regex su routes.js : "page" può essere anche un testo che dovrebbe far risultare 404 -> eseguo re.test : se è un digit l uso, altrimenti rimpiazzo il /path errato rimuovendo il /:page
      // da regex su routes.js "page" può essere solo un digit 0-9
      if(match.params && match.params.page && re.test(match.params.page)) {
        page = parseInt(match.params.page);
        offset = pageLimit * (page - 1);
      } else if (queryString !== ""){
        this.props.history.replace(`/search?${queryString}`);
      }
      this.fetchAppartamenti(pageLimit, offset, page, OrdineTipo, ordineDirezione);
    }
  }

  fetchAppartamenti(pageLimit, offset, currentPage, ordineTipo, ordineDirezione) {
    const { esercizio } = this.state;

    const filters = this.props.location && parseQuery(this.props.location.search, this.normalizeParams);
    if(_.get(esercizio, 'configurazioni.enabled_filters', []).length > 0) {
      filters.enabled_filters = _.get(esercizio, 'configurazioni.enabled_filters', [])
    }
    if (filters && filters.startDate) {
      this.setState({ query: filters });
      this.onSearch(filters, offset, currentPage, ordineTipo, ordineDirezione);
    }
  }

  onPageChanged(pageLimit, offset, currentPage){
    let { ordineTipo, ordineDirezione, ids } = this.state;
    const queryString = reBuildQueryString(window.location.search, this.normalizeParams)
    const filters = this.props.location && parseQuery(this.props.location.search, this.normalizeParams);
    if(currentPage !== this.state.currentPage && filters) {
      this.props.history.push(`/search/${currentPage}?${queryString}`);
      this.setState({offset: offset, currentPage: currentPage, isPageChanged: true });
      this.onSearch(filters, offset, currentPage, ordineTipo, ordineDirezione, ids);
    }
  }

  onUpdateLimitChanged(newPageLimit){
    const { pageLimit, filters, offset, currentPage } = this.state;
    if(newPageLimit !== pageLimit) {
      this.setState({ pageLimit: newPageLimit});
      this.onSearch(filters, offset, currentPage );
    }
  }

  onSortByChange (ordineTipo) {
    const { filters, ordineDirezione, ids } = this.state;
    this.setState({ ordineTipo });
    this.onSearch(filters, DEFAULT_OFFSET, DEFAULT_CURRENTPAGE, ordineTipo, ordineDirezione, ids);
  }

  onSortDirectionChange (ordineDirezione) {
    const { filters, ordineTipo, ids } = this.state;
    this.setState({ ordineDirezione });
    this.onSearch(filters, DEFAULT_OFFSET, DEFAULT_CURRENTPAGE, ordineTipo, ordineDirezione, ids);
  }

  onSearch(filters, offset = DEFAULT_OFFSET, currentPage = DEFAULT_CURRENTPAGE, ordineTipo, ordineDirezione, ids){

    const { pageLimit, esercizio, selectedFilters } = this.state;
    const self = this;
    this.setState({ loadingList: true, isPageChanged: true });

    const startDate = moment(filters.startDate);
    const endDate = moment(filters.endDate);

    //startdate in base a prenotazione in giornata
    if(esercizio.configurazioni.prenotazione_giornata_finoalle){
      startDate.startOf('day').add(esercizio.configurazioni.prenotazione_giornata_finoalle,'hours');
    }

    if(startDate < moment() || endDate < moment() || endDate < startDate){
      this.setState({
        warningDates: true,
        items: [],
        loadingList: false,
        isPageChanged: false
      });
    } else {
      filters.selectedFilters = selectedFilters

      this.appartamentiSvc.searchAppartamenti(esercizio, pageLimit, offset, currentPage, ordineTipo, ordineDirezione, { ...filters, ids })
        .then(({ items, total, pages, currentPage, pageLimit, url_mappa, url_filtri }) => {
          if(url_mappa && (!ids || this.filtersChanged)) {
            this.filtersChanged = false
            self.appartamentiSvc.getInfoHref(esercizio, url_mappa).then(res => {
              const punti = this.setState({
                  puntiMappa: _.get(res, "data.appartamenti") || []
                })
            })
          }

          if(url_filtri) {
            self.appartamentiSvc.getInfoHref(esercizio, url_filtri).then(res => {
              if(!isArray(res.data) && Object.keys(res.data).length > Object.keys((this.state.enabledFilters || {})).length) {
                this.setFilters(res.data)
              }
            })
          }

          if(items && items.length > 0){

            // se esistono contenuti per la pagina corrente
            self.setState({ items: items, total, pages, currentPage, pageLimit, filters, loadingList: false, isPageChanged: false  });
          } else if (total > 0 && currentPage > pages) { // senno, se comunque esistono dei contenuti ma la pagina non da risultati
            // uso pages al posto di currentPage nella chiamata - vado all'ultima pagina disponibile
            const newCurrPage = pages;
            const newOffset = pageLimit * (newCurrPage - 1);
            self.appartamentiSvc.searchAppartamenti(esercizio, pageLimit, newOffset, newCurrPage, ordineTipo, ordineDirezione, { ...filters, ids })
            .then(({ items, total, pages, currentPage, pageLimit }) => {
              if(items && items.length > 0){

                // self.props.history.replace(`/${currentPage}`);
                const queryString = reBuildQueryString(window.location.search, this.normalizeParams);
                self.props.history.replace(`/search/${currentPage}?${queryString}`);
                self.setState({ items, total, pages, currentPage, pageLimit, filters, loadingList: false, isPageChanged: false });
              }
            }).catch(err => { console.log('err', err); })
          } else if(!filters.startDate || !filters.endDate || !filters.persone || startDate < moment() || endDate < moment() || endDate < startDate){
            this.setState({
              warningDates: true,
              items: [],
              loadingList: false,
              isPageChanged: false
            });
          } else {
            this.setState({
              items: [],
              loadingList: false,
              isPageChanged: false
            });
          }
        })
        .catch(err => { console.log('err', err); });
    }
  }

  onHoverChange = (id, started) => {
    if(started) {
      this.setState({
        hoverId: id
      })
    } else {
      if(this.state.hoverId == id) {
        this.setState({ hoverId: null })
      }
    }
  }

  getItemsByIds = (ids) => {
    this.setState({
      ids,
      offset: DEFAULT_OFFSET,
      currentPage: DEFAULT_CURRENTPAGE,
    })

    const { pageLimit, filters, ordineTipo, ordineDirezione } = this.state;
    this.onSearch(filters, DEFAULT_OFFSET, DEFAULT_CURRENTPAGE, ordineTipo, ordineDirezione, ids)
  }

  setFilters = (filters) => {
    this.setState({
      enabledFilters: filters
    })
  }

  onFiltersChange = (type, selected) => {
    const oldFilters = this.state.selectedFilters[type]
    let newFilters
    if(selected.length) {
      newFilters = selected
    } else {
      if((oldFilters || []).find((item) => item.value == selected.value)) {
        newFilters = oldFilters.filter(item => item.value != selected.value)
      } else {
        newFilters = (oldFilters || []).concat(selected)
      }
    }

    const result = {
      ...this.state.selectedFilters,
      [type]: newFilters
    }

    this.setState({
      selectedFilters: result
    })
  }

  onFiltersApplied = () => {
    const { filters, ordineTipo, ordineDirezione, selectedFilters } = this.state;
    filters.selectedFilters = selectedFilters
    const queryString = reBuildQueryString(window.location.search, this.normalizeParams)
    const url = Object.entries(selectedFilters).reduce((acc, [key, values]) => {
      if(values.length == 0) return acc
      acc.push(`${key}=${values.map(v => v.value).join(",")}`)
      return acc
    }, []).join("&")

    this.filtersChanged = true
    this.props.history.push(`/search?${this.replaceFilterParams(queryString, url)}`)
    //this.onSearch(filters, DEFAULT_OFFSET, DEFAULT_CURRENTPAGE, ordineTipo, ordineDirezione );
  }

  onFiltersReset = () => {
    this.setState({
      selectedFilters: {}
    })
  }

  normalizeParams = (params) => {
    if (typeof params.persone !== 'undefined') {
      params.persone = parseInt(params.persone) || 1;
    }

    return params;
  }

  replaceFilterParams = (currentQuery, newFiltersQuery) => {
    const parsedCurrentQuery = parseQuery(currentQuery, this.normalizeParams)
    const parsedFiltersQuery = parseQuery(newFiltersQuery, this.normalizeParams)

    Object.keys(parsedCurrentQuery).forEach((key) => {
      if(filtersKeys.includes(key)) {
        delete parsedCurrentQuery[key]
      }
    })

    const result = {
      ...parsedCurrentQuery,
      ...parsedFiltersQuery
    }

    return buildQuery(result)
  }

  render(){
    const { startDate, endDate } = this.state;
    if (!startDate || !endDate) {
      return <Redirect to={"/"} />
    }

    const { items = false, esercizio, total, pages, currentPage, pageLimit, loadingList, ordineDirezione, warningDates = false, hideOrdinamento, ordineSearch, ordineTipo, residences } = this.state;
    const pagination = { total, pages, currentPage, pageLimit };

    if (!items) {
      return <main className={[styles.home,'min-h-100'].join(' ')}><div className={'loader'}><div className='spinner'><div></div><div></div></div></div></main>;
    }

    return(
      <Fragment>
      {warningDates &&
        <div className={[styles.warningMessage,'secondary-background'].join(' ')}>
          <div className={styles.warningMessageInner}>
            <p className={'secondary-color'}>{__('Le informazioni utilizzate per la ricerca non sono corrette.')} <span >{__('Prova con una nuova ricerca')}</span> <i className={['icon-close',styles.close_icon,styles.icon].join(' ')} onClick={() => this.setState({ warningDates: false })}></i></p>
          </div>
        </div>
      }
      <main className={HAS_MAP ? [styles.home, "has-map"].join(" ") : "min-h-100"}>
        <div className={styles.content} style={HAS_MAP ? { width: `${100-MAP_PERCENTAGE}%`, position: "relative" } : { position: "relative" }}>
          {<SearchBar
            search={true}
            hasRiepilogo={true}
            esercizio={esercizio}
            onSearch={this.onSearch}
            match={this.state.match}
            history={this.props.history}
            location={this.state.location}
            position={'top'}
            startDate={startDate}
            endDate={endDate}
            homePage={HAS_MAP}
            searchPage
            getResidences={this.appartamentiSvc.getResidences}
            onFiltersReset={this.onFiltersReset}
          />}
          {HAS_MAP && (
            <div className={styles.showMobile}>
              <IconButton
                bgColor="secondary-background"
                label={__("Mostra mappa")}
                onClick={() => this.setState({ mappaFullScreen: true })}
                icon="icon-map"
              />
            </div>
          )}

          <div className={loadingList ? "loader" : "loader completed"} style={{ position: "absolute", zIndex: 1000 }}><div className='spinner'><div></div><div></div></div></div>
          <div className={styles.list}>
            <div className={styles.centerColumn}>
              <List
                items={items}
                loading={false}
                mode={'v'}
                esercizio={esercizio}
                ordineTipoDefault={ordineTipo}
                ordineTipo={ordineSearch}
                onFiltersChange={this.onFiltersChange}
                onFiltersApplied={this.onFiltersApplied}
                selectedFilters={this.state.selectedFilters}
                enabledFilters={this.state.enabledFilters}
                ordineDirezioneDefault={ordineDirezione}
                ordineDirezione={['ASC','DESC']}
                hideHeader={hideOrdinamento}
                onSortByChange={this.onSortByChange}
                onSortDirectionChange={this.onSortDirectionChange}
                onPageChanged={this.onPageChanged}
                onSearchChanged={this.onSearchChanged}
                onUpdateLimitChanged={this.onUpdateLimitChanged}
                onItemRender={(data) => <CardAppartamento key={data.id} onHoverChange={(start) => this.onHoverChange(data.id, start)} mode='v' data={data} location={this.props.location && this.props.location.search} /> }
                {...pagination}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <Footer {...this.props} />
            </div>
          </div>
        </div>
        {HAS_MAP && (
          <div className={styles.hideMobile} style={{ width: `${MAP_PERCENTAGE}%` }}>
            <SearchMap esercizio={esercizio} puntiMappa={this.state.puntiMappa} activeItem={this.state.hoverId} requestItems={this.getItemsByIds} location={this.props.location && this.props.location.search} />
          </div>
        )}
        <div className={styles.showMobile}>
          <Modal
            isOpen={this.state.mappaFullScreen}
            onRequestClose={() => this.setState({ mappaFullScreen: false })}
            style={{
              content: {
                padding: 3,
                display: "flex",
                flexDirection: "column",
              }
            }}
          >
            <div className={styles.closeIcon} onClick={() => this.setState({ mappaFullScreen: false })}>
              <i className="icon-close" />
            </div>
            <SearchMap esercizio={esercizio} puntiMappa={this.state.puntiMappa} location={this.props.location && this.props.location.search} />
          </Modal>
        </div>
      </main>
    </Fragment>
    )
  }
}