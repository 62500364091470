import React, {useState} from 'react'
import {__} from "../../utils/translator";
import Slider from '@mui/material/Slider';
import {buildQuery, parseQuery} from '../../utils';


const filterNamesMap = {
  camere: "Camere",
  zona: "Zona",
  tipologie: "Tipologie",
  prezzi_ranges: "Range di prezzo",
  servizi: "Servizi",
  in_offerta: "In offerta",
  esercizi: "Esercizi",
  distanza_mare_ranges: "Distanza dal mare"
}

/**
 * @prop {*} filter
 */
const Filter = (props) => {
  const {filter} = props

  const options = Object.values(filter.filtro).map((value) => {
    if ('min' in value && 'max' in value) {
      return {
        value: value.min + "-" + value.max,
        label: value.min + " - " + value.max
      }
    } else if ('servizio' in value) {
      return {
        value: value.servizio.id,
        label: value.servizio.nome
      }
    } else if ('camere' in value) {
      return {
        value: value.camere,
        label: value.camere
      }
    } else if ('offerta' in value) {
      return {
        value: value.offerta,
        label: value.offerta ? __("Sì") : __("No")
      }
    } else if ('tipologia' in value) {
      return {
        value: value.tipologia.id,
        label: value.tipologia.nome
      }
    } else if ('zona' in value) {
      return {
        value: value.zona.id,
        label: value.zona.nome
      }
    }
  }).filter(o => !!o)

  if (options.length <= 1) return null

  if (filter.type == "camere" || filter.type == "prezzi_ranges" || filter.type == "distanza_mare_ranges") {
    let marks = []
    let step = null
    if (filter.type == "camere") {
      marks = options.map((option) => {
        return {
          value: option.value,
          label: option.label
        }
      })
      step = 1
    }

    if (filter.type == "prezzi_ranges" || filter.type == "distanza_mare_ranges") {
      marks = options.map((option, index) => {
        return {
          value: parseInt(option.value.split("-")[index == 0 ? 0 : 1]),
          label: option.label.split(" - ")[index == 0 ? 0 : 1]
        }
      })
    }

    let defaultSliderValues
    if (!props.selectedFilters || !props.selectedFilters[filter.type]) {
      defaultSliderValues = [marks[0].value, marks[marks.length - 1].value]
    } else if (props.selectedFilters[filter.type].length == 1) {
      let min = props.selectedFilters[filter.type][0].value
      let max = props.selectedFilters[filter.type][0].value
      if (filter.type == "prezzi_ranges" || filter.type == "distanza_mare_ranges") {
        min = parseInt(min.split("-")[1])
        max = parseInt(max.split("-")[1])
      }
      defaultSliderValues = [min, max]
    } else {
      let min = props.selectedFilters[filter.type][0].value
      let max = props.selectedFilters[filter.type][props.selectedFilters[filter.type].length - 1].value
      if (filter.type == "prezzi_ranges" || filter.type == "distanza_mare_ranges") {
        min = parseInt(min.split("-")[1])
        max = parseInt(max.split("-")[1])
      }
      defaultSliderValues = [min, max]
    }
    const [sliderValues, setSliderValues] = useState(defaultSliderValues)

    return <div key={filter.type} style={{alignSelf: 'stretch', marginBottom: 10}}>
      <div style={{
        marginTop: 10,
        marginBottom: 10,
        fontSize: 20,
        fontWeight: 'bold'
      }}>{filterNamesMap[filter.type]}</div>
      <Slider
        value={sliderValues}
        min={marks[0].value}
        max={marks[marks.length - 1].value}
        step={step}
        marks={marks}
        onChangeCommitted={(e, value) => {
          const min = value[0]
          const max = value[1]
          if (filter.type == "camere") {
            const selectedOptions = options.filter((o) => {
              return o.value >= min && o.value <= max
            })
            props.onFiltersChange(filter.type, selectedOptions)
          } else if (filter.type == "prezzi_ranges" || filter.type == "distanza_mare_ranges") {
            const selectedOptions = options.filter((o) => {
              const higherValue = o.value.split("-")[1]
              return higherValue >= min && higherValue <= max
            })
            props.onFiltersChange(filter.type, selectedOptions)
          }
        }}
        onChange={(e, value) => {
          setSliderValues(value)
        }}
        valueLabelDisplay="auto"
      />
    </div>
  } else {
    return <div key={filter.type} style={{alignSelf: 'stretch', marginBottom: 10}}>
      <div style={{marginTop: 10, fontSize: 20, fontWeight: 'bold'}}>{filterNamesMap[filter.type]}</div>
      <div style={{display: 'flex', flexWrap: 'wrap'}}>
        {options.map((option, index) => {
          return <div key={index + filter.type} style={{
            display: 'flex',
            marginTop: 10,
            marginRight: 15,
            alignItems: 'center'
          }}>
            <input type={'checkbox'}
                   checked={!!(props.selectedFilters[filter.type] || []).find((item) => item.value == option.value)}
                   name={option.label} id={option.value}
                   onChange={() => {
                     props.onFiltersChange(filter.type, option)
                   }
                   }/>
            <div onClick={() => {
              props.onFiltersChange(filter.type, option)
            }} style={{marginLeft: 5, cursor: 'pointer'}}>{option.label}</div>
          </div>
        })}
      </div>
    </div>
  }
}

export default Filter

export const filtersKeys = ["esercizi", "camere", "zona", "tipologie", "prezzi_ranges", "distanza_mare_ranges", "servizi", "in_offerta"]

export const getFiltersParamsFromQuery = () => {
  const query = parseQuery(window.location.search)
  const filtersEntries = Object.entries(query).filter(([key, value]) => {
    return filtersKeys.includes(key)
  })
  return buildQuery(filtersEntries)
}