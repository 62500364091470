import React from 'react'
import Select, { components } from 'react-select'

/**
 * 
 * @prop {*} options
 * @prop {*} onChange
 * @prop { } isMulti
 * @prop { } containerStyle
 * @prop { } isSearchable
 * @prop { } closeMenuOnSelect
 */
const Select2 = (props) => {

  const onChange = (selected) => {
    props.onChange(selected)
  }

  return (
    <div>
      <Select
        styles={{
          container: (provided) => ({
            ...provided,
            ...props.containerStyle
          }),
        }}
        className={props.className || "react-select-container"}
        classNamePrefix="react-select"
        isClearable={true}
        isSearchable={props.isSearchable}
        isMulti={props.isMulti}
        options={props.options}
        closeMenuOnSelect={props.closeMenuOnSelect}
        hideSelectedOptions={false}
        onChange={onChange}
        components={{
          Option
        }}
        value={props.value}
        placeholder={props.placeholder}
      />
    </div>
  )
}

const Option = (props) => {
	return (
		<div>
			<components.Option {...props}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<input
						type="checkbox"
						checked={props.isSelected}
						onChange={() => null}
            style={{ marginRight: 10 }}
					/>{" "}
					<label>{props.label}</label>
				</div>
			</components.Option>
		</div>
	);
};

export default Select2