import React, { Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment';

import EsercizioSvc from './../../services/esercizio';
import AppartamentiSvc from './../../services/appartamenti';
import List from '../../components/List'
import BannerHome from '../../components/BannerHome'
import CardAppartamento from '../../components/CardAppartamento';
import SearchBar from '../../components/SearchBar';
import styles from './style.module.css';
import { __ } from './../../utils/translator';
import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet';
import SearchMap, { SHOW_MAP_ON_HOME as HAS_MAP, MAP_PERCENTAGE } from "../../components/SearchMap";
import Footer from '../../components/Footer';
import IconButton from "../../components/IconButton";
import Modal from "react-modal";

const DEFAULT_LIMIT = 12;
const DEFAULT_CURRENTPAGE = 1;
const DEFAULT_OFFSET = 0;

export default class Home extends React.Component {

  constructor(props){
    super(props);

    this.state = { 
      pageLimit: DEFAULT_LIMIT,
      currentPage: DEFAULT_CURRENTPAGE,
      offset: DEFAULT_OFFSET,
      ordineDirezione: 'ASC',
      ordineTipo: 'nome',
      ordineHome: ['nome'],
      hideOrdinamento: false,
      residences: [],
      ...props
    }

    this.fetchData = this.fetchData.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
    this.onSortByChange = this.onSortByChange.bind(this);
    this.onSortDirectionChange = this.onSortDirectionChange.bind(this);
    this.onUpdateLimitChanged = this.onUpdateLimitChanged.bind(this);
    this.appartamentiSvc = new AppartamentiSvc();
    this.esercizioSvc = new EsercizioSvc();

    if(HAS_MAP) {
      this.fetchMappa(props.esercizio)
    }
  }

  componentDidMount(){

    const { esercizio, ordineDirezione, pageLimit, match, hideOrdinamento, ordineHome, ordineTipo } = this.state;

    if (esercizio && pageLimit) {
      const hideOrdine = _.get(esercizio,'configurazioni.hideOrdinamentoHome') ? _.get(esercizio,'configurazioni.hideOrdinamentoHome') : hideOrdinamento;
      const OrdineHome = _.get(esercizio,'configurazioni.ordineHome') ? _.get(esercizio,'configurazioni.ordineHome') : ordineHome;
      const OrdineTipo = OrdineHome[0] ? OrdineHome[0] : ordineTipo;
      this.setState({
        hideOrdinamento: hideOrdine,
        ordineHome: OrdineHome,
        ordineTipo: OrdineTipo
      });

      let page = 1;
      let offset = 0;
      // da regex su routes.js "page" può essere solo un digit 0-9
      if(match.params && match.params.page) {
        page = parseInt(match.params.page);
        offset = pageLimit * (page - 1);
      }
      this.fetchData(pageLimit, offset, page, OrdineTipo, ordineDirezione);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.page !== this.props.match.params.page && !this.state.isPageChanged) {
      const { pageLimit } = this.state;
      const newCurrPage = parseInt(this.props.match.params.page);
      const newOffset = pageLimit * (newCurrPage - 1);
      this.setState({ currentPage: newCurrPage, offset: newOffset});
      this.fetchData(pageLimit, newOffset, newCurrPage);
    }
  }

  fetchMappa = () => {
    const { esercizio } = this.state
    this.appartamentiSvc.getPuntiMappa(esercizio).then(res => {
      this.setState({ puntiMappa: res.appartamenti || [] })
    })
  }
  
  /**
   * 
   * @param {*} pageLimit 
   * @param {*} offset 
   * @param {*} currentPage 
   * @param {*} ordineTipo 
   * @param {*} ordineDirezione 
   */
  fetchData( pageLimit, offset, currentPage, ordineTipo, ordineDirezione){
    const self = this;
    const { esercizio } = this.state;
    this.setState({ loadingList: true });
    this.appartamentiSvc.getAppartamenti(esercizio, pageLimit, offset, currentPage, ordineTipo, ordineDirezione)
    .then(({ items, total, pages, currentPage, pageLimit }) => {
      if(items && items.length > 0){
        // se esistono contenuti per la pagina corrente
        self.setState({ items, total, pages, currentPage, pageLimit, loadingList: false, isPageChanged: false });
      } else if (total > 0) {
        // senno, se comunque esistono dei contenuti ma la pagina non da risultati
        if(currentPage > pages) {
          // uso pages al posto di currentPage nella chiamata - vado all'ultima pagina disponibile
          const newCurrPage = pages;
          const newOffset = pageLimit * (newCurrPage - 1);
          self.appartamentiSvc.getAppartamenti(esercizio, pageLimit, newOffset, newCurrPage, ordineTipo, ordineDirezione)
          .then(({ items, total, pages, currentPage, pageLimit }) => {
            if(items && items.length > 0){
              self.props.history.replace(`/${currentPage}`);                
              self.setState({ items, total, pages, currentPage, pageLimit, loadingList: false, isPageChanged: false });
            }
          }).catch(err => { console.log('err', err); })
        }
      }
    })
    .catch(err => { console.log('err', err); })
  }

  onPageChanged(pageLimit, offset, currentPage){
    this.props.history.push(`/${currentPage}`);
    if (parseInt(currentPage) !== parseInt(this.state.currentPage)) { 
      const { ordineTipo, ordineDirezione } = this.state;
      this.setState({ pageLimit, offset, currentPage , isPageChanged: true});
      this.fetchData(pageLimit, offset, currentPage, ordineTipo, ordineDirezione);  
    }
  }

  onUpdateLimitChanged(newPageLimit){
    const { offset, currentPage, pageLimit } = this.state;
    if(newPageLimit !== pageLimit) { 
      const { ordineTipo, ordineDirezione } = this.state;
      this.setState({ pageLimit: newPageLimit});
      this.fetchData(newPageLimit, offset || 0, currentPage, ordineTipo, ordineDirezione);  
    }
  }

  onSortByChange (ordineTipo) {
    this.setState({ ordineTipo });
    const { pageLimit, ordineDirezione, currentPage = DEFAULT_CURRENTPAGE, offset = DEFAULT_OFFSET } = this.state;
    this.fetchData(pageLimit, offset, currentPage, ordineTipo, ordineDirezione);
  }

  onSortDirectionChange (ordineDirezione) {
    this.setState({ ordineDirezione });
    const { pageLimit, ordineTipo, currentPage = DEFAULT_CURRENTPAGE, offset = DEFAULT_OFFSET } = this.state;
    this.fetchData(pageLimit, offset, currentPage, ordineTipo, ordineDirezione);
  }

  onHoverChange = (id, started) => {
    if(started) {
      this.setState({
        hoverId: id
      })
    } else {
      if(this.state.hoverId == id) {
        this.setState({ hoverId: null })
      } 
    }
  }

  render(){

    const { items, esercizio, total, pages, currentPage, pageLimit, loadingList, ordineDirezione, hideOrdinamento, ordineHome, ordineTipo, residences } = this.state;
    const pagination = {total, pages, currentPage, pageLimit};
    if (!items || !esercizio) return <main className={[styles.home,'min-h-100'].join(' ')}><div className={'loader'}><div className='spinner'><div></div><div></div></div></div></main>;

    const bannerHome = this.esercizioSvc.getBannerInfo();
    
    const residencesOptions = residences.map((item) => {
      return {
        value: item.id,
        label: item.nome
      }
    })

    const searchBarRef = document.getElementById('home-search-bar')
    const searchHeight = (searchBarRef && searchBarRef.clientHeight) || 133

    return(
      <div className={styles.home} style={{ position: "relative" }}>
        {bannerHome && bannerHome.image &&
          <BannerHome image={bannerHome.image} title_h1={bannerHome.title_h1} title_h2={bannerHome.title_h2}  />
        }
        <div style={{ position: "sticky", top: 0, zIndex: 100 }}>
          <div id="home-search-bar">
          <SearchBar
            search={false}
            searchOpen={true}
            esercizio={esercizio}
            onSearch={this.onSearch}
            match={this.state.match}
            history={this.props.history}
            location={this.state.location}
            titolo={__('cerca_appartamento')}
            position={'top'}
            homePage
            getResidences={this.appartamentiSvc.getResidences}
          />
          </div>
          {HAS_MAP && (
            <div className={styles.hideMobile} style={{ position: "absolute", right: 0, width: `${MAP_PERCENTAGE}%`, height: `calc(100vh - ${searchHeight}px)` }}>
              <SearchMap esercizio={esercizio} puntiMappa={this.state.puntiMappa} activeItem={this.state.hoverId} location={this.props.location && this.props.location.search} isHome={true} />
            </div>
          )}
        </div>
        {HAS_MAP && (
          <div className={styles.showMobile}>
            <IconButton
              bgColor="secondary-background"
              label={__("Mostra mappa")}
              onClick={() => this.setState({ mappaFullScreen: true })}
              icon="icon-map"
            />
            <Modal
              isOpen={this.state.mappaFullScreen}
              onRequestClose={() => this.setState({ mappaFullScreen: false })}
              style={{
                content: {
                  padding: 3,
                  display: "flex",
                  flexDirection: "column",
                }
              }}
            >
              <div className={styles.closeIcon} onClick={() => this.setState({ mappaFullScreen: false })}>
                <i className="icon-close" />
              </div>
              <SearchMap esercizio={esercizio} puntiMappa={this.state.puntiMappa} location={this.props.location && this.props.location.search} />
            </Modal>
          </div>
        )}
        <div
          className={HAS_MAP ? [styles.content, "has-map"].join(" ") : styles.content}
          style={HAS_MAP ? { width: `${100-MAP_PERCENTAGE}%` } : {}}
        >
          <div className={styles.list}>
            <div className={styles.centerColumn} style={{ marginBottom: 10 }}>
              <List
                items={items}
                mode={'v'}
                columns={3}
                ordineDirezione={['ASC','DESC']}
                ordineDirezioneDefault={ordineDirezione}
                ordineTipo={ordineHome}
                ordineTipoDefault={ordineTipo}
                hideHeader={hideOrdinamento}
                loading={loadingList}
                esercizio={esercizio}
                onPageChanged={this.onPageChanged}
                onSortByChange={this.onSortByChange}
                onSearchChanged={this.onSearchChanged}
                onUpdateLimitChanged={this.onUpdateLimitChanged}
                onSortDirectionChange={this.onSortDirectionChange}
                onItemRender={(data) => (
                  <CardAppartamento
                    key={data.id}
                    mode='v'
                    data={data}
                    onHoverChange={(start) => this.onHoverChange(data.id, start)}
                  />
                )}
                {...pagination}
              />
            </div>
            <Footer {...this.props} />
          </div>
        </div>
      </div>
    )
  }
}