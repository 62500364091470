import React, { Component } from "react";
import style from './style.module.css';

export default class Popup extends Component {

  constructor(props){
    super(props);
    this.state = {
      ...props
    };

    this.handleClick = this.handleClick.bind(this);
    this.setRef = this.setRef.bind(this);
  }

  componentDidMount(){
    document.addEventListener('click', this.handleClick, false)
  }

  componentWillUnmount(){
    document.removeEventListener('click', this.handleClick, false)
  }

  handleClick(event){
    const { toggle } = this.props;
    if (toggle && this.ref && !this.ref.contains(event.target)) {
      this.props.onToggle();
    }
  }

  setRef(node) {
    this.ref = node;
  }

  render (){  
    const classes = [style.popup];
    const outerClass = [];
    const { direction, toggle, classe } = this.props;
  
    if (direction) {
      classes.push(style[direction]);
    }
    if (classe) {
      classes.push(style[classe]);
      outerClass.push(style[classe]);
    }

    if (toggle) {
      classes.push(style['open']);
    }
    
    return (
      <div ref={this.setRef} className={['noselect',style.container].join(' ')}>
        <div className={[style.outer,outerClass].join(' ')}>
          <div className={style.inner}>
            <div className={style.button}>
              {this.props.button}
            </div>
            <div className={classes.join(' ')}>
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

