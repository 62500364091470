import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import style from './style.module.css';

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

class Pagination extends Component {
  constructor(props) {
    super(props);

    const { totalRecords = null, pageLimit = 30, pageNeighbours = 0, currentPage = 1 } = props;

    this.pageLimit = typeof pageLimit === "number" ? pageLimit : 30;
    this.totalRecords = typeof totalRecords === "number" ? totalRecords : 0;

    this.pageNeighbours =
      typeof pageNeighbours === "number"
        ? Math.max(0, Math.min(pageNeighbours, 2))
        : 0;

    this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);

    this.state = { currentPage: parseInt(currentPage) };

  }

  componentDidMount() {
    //this.gotoPage(1);
  }

  // componentWillReceiveProps(nextProps) {
  componentDidUpdate(prevProps, prevState){
    if (this.pageLimit !== this.props.pageLimit) {
      this.pageLimit = typeof this.props.pageLimit === "number" ? this.props.pageLimit : this.pageLimit;
      this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);
      this.gotoPage(1);
    } else if (this.totalRecords !== this.props.totalRecords) {
      this.totalRecords = typeof this.props.totalRecords === "number" ? this.props.totalRecords : this.totalRecords;
      this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);
      this.setState({ currentPage: this.props.currentPage });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.currentPage !== prevState.currentPage){
      return { 
        currentPage: nextProps.currentPage,
      };
    }
    else return null;
  }

  
  gotoPage = page => {
    const { onPageChanged } = this.props;

    const currentPage = Math.max(0, Math.min(page, this.totalPages));

    const paginationData = {
      currentPage,
      totalPages: this.totalPages,
      pageLimit: this.pageLimit,
      totalRecords: this.totalRecords
    };

    this.setState({ currentPage }, () => onPageChanged(paginationData));
  };

  handleClick = (page, evt) => {
    evt.preventDefault();
    this.gotoPage(page);
  };

  handleMoveLeft = evt => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage - this.pageNeighbours);
  };

  handleMoveRight = evt => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage + this.pageNeighbours);
  };

  fetchPageNumbers = () => {
    const totalPages = this.totalPages;
    const currentPage = this.state.currentPage;
    const pageNeighbours = this.pageNeighbours;

    const totalNumbers = this.pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 1 ? leftBound : 1;
      const endPage = rightBound < beforeLastPage + 1 ? rightBound : beforeLastPage + 1;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 1;
      const rightSpill = endPage < (beforeLastPage + 1);

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset + 1, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset - 1);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [...pages];
    }

    return range(1, totalPages);
  };

  render() {
    if (!this.totalRecords) return null;

    if (this.totalPages === 1) return null;

    const { currentPage } = this.state;
    const pages = this.fetchPageNumbers();

    return (
      <Fragment>
        <div className={style.paginationContainer}>
          <ul className={style.pagination}>
            {pages.map((page, index) => {
              if (page === LEFT_PAGE)
                return (
                  <li key={index} className={[style.prev_item,'page-item'].join(' ')}>
                    <div
                      className={style.page_link}
                      aria-label="Previous"
                      onClick={this.handleMoveLeft}
                    >
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </div>
                  </li>
                );

              if (page === RIGHT_PAGE)
                return (
                  <li key={index} className="page-item">
                    <div
                      className={style.page_link}
                      aria-label="Next"
                      onClick={this.handleMoveRight}
                    >
                      <span aria-hidden="true">&raquo;</span>
                      <span className="sr-only">Next</span>
                    </div>
                  </li>
                );

              return (
                <li
                  key={index}
                  className={`page-item${
                    currentPage === page ? ` active ${style.active}` : ""
                  }`}
                >
                  <div
                    className={style.page_link}
                    onClick={e => this.handleClick(page, e)}
                  >
                    {page}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </Fragment>
    );
  }
}

Pagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number,
  pageNeighbours: PropTypes.number,
  onPageChanged: PropTypes.func
};

export default Pagination;
