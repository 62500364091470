import _ from 'lodash';
import {__} from './translator';

export const getRightLang = (object, valueToSelect, defaultVal, currentLang) => {

  let lastDefault = _.get(object, `${valueToSelect}.it`, defaultVal);
  let firstDefault = _.get(object, `${valueToSelect}.en`, defaultVal);
  let current = _.get(object, `${valueToSelect}.${currentLang}`, defaultVal);
  const result = _.assign({}, lastDefault, firstDefault, current);

  return Object.keys(result).length > 0 ? result : (current || firstDefault || lastDefault);
}

export const getRightLegal = (confObj, path, lang) => {

  const tipo = _.get(confObj, `${path}.tipo`, '');

  // seleziono il link dalle configurazioni se il tipo è 'link'
  if (tipo === 'link') {
    return getRightLang(confObj, `${path}.parametri`, '', lang)[0];
  } else { // senno linko alla pagina interna
    return window.basename + '/' + path;
  }
}

export const getDistanza = (distanze) => {

  if (distanze) {
    let distanzaDalMare = '';
    let distanzaDalCentro = '';
    let distanzaDalleTerme = '';
    let distanzaDallePiste = '';
    let newDistanze = {};
    distanze.filter(distanza => {
      if (_.get(distanza, 'zona')) {
        if (distanza.zona.nome === 'mare') {
          distanzaDalMare = `${distanza.distanza} ${__('m dal ' + distanza.zona.nome)}` || '';
          newDistanze[distanza.zona.nome] = distanzaDalMare;
        }
        if (distanza.zona.nome === 'terme') {
          distanzaDalleTerme = `${distanza.distanza} ${__('m dalle ' + distanza.zona.nome)}` || '';
          newDistanze[distanza.zona.nome] = distanzaDalleTerme;
        }
        if (distanza.zona.nome === 'centro') {
          distanzaDalCentro = `${distanza.distanza} ${__('m dal ' + distanza.zona.nome)}` || '';
          newDistanze[distanza.zona.nome] = distanzaDalCentro;
        }
        if (distanza.zona.nome === 'pistesci') {
          distanzaDallePiste = `${distanza.distanza} ${__('m dalle ' + distanza.zona.nome)}` || '';
          newDistanze[distanza.zona.nome] = distanzaDallePiste;
        }
      }
      return null;
    });
    return newDistanze;
  }
}

export const parseQuery = (queryString, callback) => {
  let query = {};
  if (!queryString) {
    return query
  }
  if (callback) {
    queryString = reBuildQueryString(queryString, callback)
  }
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export const buildQuery = (params) => {
  return Object.entries(params).map(([key, value]) => {
    return `${key}=${value}`
  }).join("&")
}

export const reBuildQueryString = (string, callback) => {
  return buildQuery(callback(parseQuery(string)));
}

export const uniqid = () => {
  let chars = 'abcdefghijklmnopqrstuvwxyz0123456789'.split('');
  let id = '';
  for (let i = 0; i < 10; i++) {
    let index = Math.round(Math.random() * (chars.length - 1));
    id += chars[index];
  }
  return id;
};

/**
 * calculate the number of nights between 2 dates
 * @param {*} date1
 * @param {*} date2
 */
export const numOfNights = (date1, date2) => {
  //Get 1 day in milliseconds
  const one_day = 1000 * 60 * 60 * 24;

  // Convert both dates to milliseconds
  const date1_ms = date1.getTime();
  const date2_ms = date2.getTime();

  // Calculate the difference in milliseconds
  const difference_ms = date2_ms - date1_ms;

  // Convert back to days and return
  return Math.round(difference_ms / one_day);
}

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const getTitoloAppartamento = (dataAppartamento, tipo) => {
  if (!Array.isArray(tipo)) {
    return _.get(dataAppartamento, 'residence.tipologia.nome', '') + ' ' + _.get(dataAppartamento, 'residence.nome', '') + ' - ' + _.get(dataAppartamento, 'nome.testo')
  }

  return tipo.map((element) => {
    if (element === 'residence_tipologia') {
      return _.get(dataAppartamento, 'residence.tipologia.nome', '')
    }
    if (element === 'residence_nome') {
      return _.get(dataAppartamento, 'residence.nome', '')
    }
    if (element === 'appartamento_nome') {
      return _.get(dataAppartamento, 'nome.testo', '')
    }
    if (element === 'appartamento_interno') {
      return _.get(dataAppartamento, 'interni[0].interno', '')
    }

    return element
  }).join(' ')
}
