import React from "react";
import style from './style.module.css';
import { Pannellum } from "pannellum-react";

export default class Panorama extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      ...props
    }
    
  }
  
  render (){
    
    const { image } = this.state;
    
    return (
      <Pannellum
          width="100%"
          height="25vw"
          image={image}
          pitch={10}
          yaw={180}
          hfov={110}
          autoLoad
      ></Pannellum>
    )
  }
}
