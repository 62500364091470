import HttpClient from './HttpClient';
import moment from 'moment';
import _ from 'lodash';

export const MAP_PERCENTAGE = _.get(window, "esercizio.configurazioni.percentuale_mappa_ricerca", 0)
export const HAS_MAP = MAP_PERCENTAGE > 0

export default class Appartamenti {

  constructor(){
    this.httpClient = new HttpClient();
  }

  getResidences = async (esercizio) => {
    const url = `/appartamenti/agenzie/agenzia/${esercizio.id}/residences`

    const options = {
      headers: {}
    };

    try {
      const response = await this.httpClient.get(url, options)
      return response.data
    } catch(e) {
      throw new Error(e) 
    }
  }

  getPuntiMappa = async (esercizio) => {
    const url = `/appartamenti/agenzie/agenzia/${esercizio.id}/mappa`;
    try {
      const response = await this.httpClient.get(url, { headers: {} })
      return response.data
    } catch(e) {
      throw new Error(e) 
    }
  }

  getAppartamenti(esercizio, pageLimit, offset, currentPage, ordineTipo = 'nome', ordineDirezione = 'ASC') {
    const limit = pageLimit + offset - 1;
    const url = `/appartamenti/${esercizio.lang}/appartamenti/${esercizio.id}?ordineTipo=${ordineTipo}&ordineDirezione=${ordineDirezione}&offset=${offset}&limit=${limit}`;
    const options = {};

    return new Promise((resolve, reject) => {

      this.httpClient.get(url, options)
      .then(function ({ headers, data }) {
        let items = [];
        let total = 0;
        let pages = 0;
        if (headers && headers['content-range']) {
          items = headers['content-range'].split('/');
          total = parseInt(items[items.length -1]);
          items = Object.values(data);
          pages = Math.ceil(total / pageLimit);
        } else {
          total = items.length;
          items = data;
          pages = Math.ceil(total / pageLimit);
        }
        return resolve({ items, total, pages, currentPage, pageLimit});
      })
      .catch(function (error) {
        return reject(error);
      });
    }) 
  }

  /**
   * 
   * @param {*} esercizio 
   * @param {*} pageLimit 
   * @param {*} offset 
   * @param {*} currentPage 
   * @param {*} ordineTipo 
   * @param {*} ordineDirezione 
   * @param {*} filters 
   */
  searchAppartamenti(esercizio, pageLimit, offset, currentPage, ordineTipo = 'nome', ordineDirezione = 'ASC', filters){
    const { endDate, startDate, persone, appartamento, discount, ids, residenceId, enabled_filters, selectedFilters } = filters;
    const limit = pageLimit + offset - 1;
    let url = `/appartamenti/${esercizio.lang}/availability/${esercizio.id}?search[arrival]=${startDate}&search[departure]=${endDate}&search[persone]=${persone}&offset=${offset}&limit=${limit}`;

    if(ids) {
      for(const id of ids) {
        url += `&search[appartamenti][]=${id}`
      }
    }
    if (appartamento){
      url = `${url}&search[appartamenti][]=${appartamento}`;
    }
    if (ordineDirezione){
      url = `${url}&ordineDirezione=${ordineDirezione}`;
    }
    if (ordineTipo){
      url = `${url}&ordineTipo=${ordineTipo}`;
    }
    if (discount){
      url = `${url}&search[buono_sconto]=${discount}`
    }
    if (residenceId) {
      url = `${url}&search[residences]=${residenceId}`
    }
    if(enabled_filters && enabled_filters.length > 0) {
      enabled_filters.forEach((filter) => {
        url = `${url}&search[enabledFilters][]=${filter}`
      })
    }
    if(selectedFilters) {
      Object.keys(selectedFilters).forEach((filter_key) => {
        selectedFilters[filter_key].forEach((value) => {
          url = `${url}&search[filters][${filter_key}][]=${value.value}`
        })
      })
    }

    const options = {};

    return new Promise((resolve, reject) => {
    
      this.httpClient.get(url, options)
      .then(({ headers, data }) => {
        let items = [];
        let total = 0;
        let pages = 0;
        let url_filtri = null
        let url_mappa = null
        if (!data.error) {
          if (headers && headers['content-range']) {
            const itemsnum = headers['content-range'].split('/');
            total = parseInt(itemsnum[itemsnum.length -1]);
            items = Object.values(data);
            pages = Math.ceil(total / pageLimit);
          } else {
            total = items.length;
            items = Object.values(data);
            pages = Math.ceil(total / pageLimit);
          }
          if (HAS_MAP && headers) {
            url_filtri = headers["link-endpoint-filtri"]
            url_mappa = headers["link-endpoint-mappa"]
          }
        }
        return resolve({ items, total, pages, currentPage, pageLimit, url_filtri, url_mappa });
      })
      .catch(function (error) {
        return reject(error);
      });
      
    })
  }


  getAppartamento(esercizio, detail){
    return this.httpClient.get(`/appartamenti/${esercizio.lang}/appartamento/${esercizio.id}/${detail}`);
  }

  /**
   * 
   * @param {int} esercizio 
   * @param {data} dal 
   * @param {int} appartamento 
   */
  getCalendar(esercizio, dal, al, appartamento) {

    let url = `/appartamenti/calendario/${esercizio}/${dal}/${al}`;
    if(appartamento) {
      url = `${url}/${appartamento}`;
    }
    return this.httpClient.get(url);
  }

  getInfoPagamento(esercizio, dal, al, prezzo, metodoPagamenti) {
    return this.httpClient.get(`/${esercizio.lang}/agenzie/agenzia/${esercizio.id}/payment/${metodoPagamenti}/sentences?arrival=${dal}&departure=${al}&price=${prezzo}`);
  }

  getInfoHref(esercizio, href) {
    return this.httpClient.get(`/${esercizio.lang}${href.split('api')[1]}`);
  }
  
  postRichiestaInformazioni(data, esercizio, dal, al, appartamento, persone, prezzo, tariffa ) {
   
    let url = `/appartamenti/${esercizio.lang}/agenzie/agenzia/${esercizio.id}/information/`;

    if (dal && al){
      url = `${url}?portale=personale&arrivo=${dal}&partenza=${al}`;
    } else {
      dal = moment().format('YYYY-MM-DD');
      al = moment().add(1, 'days').format('YYYY-MM-DD');
      url = `${url}?portale=personale&arrivo=${dal}&partenza=${al}`;
    }
    if (appartamento){
      url = `${url}&appartamento[appartamento]=${appartamento}`;
    }
    if (persone){
      url = `${url}&appartamento[persone]=${persone}`;
    }
    if (prezzo){
      url = `${url}&appartamento[prezzo]=${prezzo}`;
    }
    if (tariffa){
      url = `${url}&tariffa=${tariffa}`;
    }
    
    return this.httpClient.post(url,data);
  }
  
  postPrenotazione(data, href, esercizio) {
    let url =  `/appartamenti/${esercizio.lang}/${esercizio.type}${href.split('api')[1]}`;
    return this.httpClient.post(url, data);
  }

  getPrenotazione(esercizio,prenotazione,ourseed,ourcheck) {
    return this.httpClient.get(`/appartamenti/${esercizio.lang}/prenotazione/agenzia/${esercizio.id}/${prenotazione}?ourseed=${ourseed}&ourcheck=${ourcheck}`);
  }
}
