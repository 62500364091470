import React from "react";
import style from './style.module.css';
import { __ } from './../../utils/translator'

/**
 * @param name: string
 * @param label: string
 * @param required?: boolean
 * @param value?: string
 * @param defaultValue?: string
 * @param onChange: (e) => void
 * @param error?: string
 * @param dimensionClass: string
 * @param maxWidth: number
 */

const TextInput = (props) => {
  return (
    <div className={[style.inputGroup, `inputGroup.${props.dimensionClass}`].join(' ')} key={props.name} style={{ maxWidth: props.maxWidth }}>
      <label htmlFor={props.name}>{__(props.label)}{props.required && __(' *')}</label>
      <input type={'text'} name={props.name} id={props.name} value={props.value} defaultValue={props.defaultValue} onChange={props.onChange} />
      {props.error &&
        <p className={style.error}>{__(props.error)}</p>
      }
    </div>
  )
}

export default TextInput