import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Map as LeafletMap, TileLayer, Marker, Popup, Tooltip } from 'react-leaflet';
import L from "leaflet"
import MarkerCluster from "../MarkerCluster"
import styles from './style.module.css';
import _ from "lodash";
import HomeIcon from "../Icons/HomeIcon";
import Button from "../Button";
import IconText from "../IconText";
import { __ } from '../../utils/translator';
import Gallery from '../Gallery';

export const MAP_PERCENTAGE = _.get(window, "esercizio.configurazioni.percentuale_mappa_ricerca", 0)
export const DISABLE_RELOAD_ON_MAP_MOVE = _.get(window, "esercizio.configurazioni.disabilita_lista_controllata_da_mappa", false)
export const HAS_MAP = MAP_PERCENTAGE > 0
export const SHOW_MAP_ON_HOME = HAS_MAP && !_.get(window, "esercizio.configurazioni.disabilita_mappa_su_home", false)

const createPinEsercizio = (id) => {
	return L.divIcon({
		className: "map-esercizio",
		iconSize: [65, 32],
		itemId: id,
		html: `
		<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/></svg>
		`
	})
}

const createPin = (retta, id, active) => {
	const p = retta ? retta.prezzo.toFixed(2) : "0"
	const prezzoDecimale = !p.endsWith("00")
	return L.divIcon({
		className: active ? "map-price active" : "map-price",
		iconSize: [prezzoDecimale ? 80 : 65, 32],
		itemId: id,
		html: retta ? `
    <div>
      ${prezzoDecimale ? p : retta.prezzo.toFixed(0)}€
    </div>` : `
		<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/></svg>
		`
	})
}

const SearchMap = ({ esercizio, puntiMappa, activeItem, requestItems, location, isHome = false }) => {
	const mapRef = useRef()
	const [lastRequestedPoints, setLastRequestedPoints] = useState([])

	useEffect(() => {
		//quando cambiano i punti li centro nella mappa
		if(!mapRef.current) {
			return
		}
		if(!puntiMappa || puntiMappa.length < 1) { return }
		if(puntiMappa.length == 1) {
			const punto = puntiMappa[0]
			mapRef.current.leafletElement.setView([punto.appartamento.latitudine, punto.appartamento.longitudine], 14)
		} else {
			const latLngs = puntiMappa.map(punto => L.latLng(punto.appartamento.latitudine, punto.appartamento.longitudine))
			mapRef.current.leafletElement.fitBounds(latLngs)
		}
	}, [puntiMappa])

	const onMove = useCallback((a) => {
		if(DISABLE_RELOAD_ON_MAP_MOVE) return

		const points = puntiMappa || []
		const mapBounds = mapRef.current.leafletElement.getBounds()
		const visiblePoints = points.filter(item => {
			const point = L.latLng(item.appartamento.latitudine, item.appartamento.longitudine)
			return mapBounds.contains(point)
		})
		const ids = visiblePoints.length == points.length ? [] : visiblePoints.map(item => item.appartamento.id).sort()
		if(lastRequestedPoints.toString() != ids.toString()) {
			requestItems && requestItems(ids)
			setLastRequestedPoints(ids)
		}
	}, [puntiMappa, lastRequestedPoints])

	return (
		<div className={styles.map}>
			<LeafletMap
				ref={mapRef}
				className="markercluster-map"
				center={[esercizio.esercizio.latitudine,esercizio.esercizio.longitudine]}
				zoom={10}
				maxZoom={18}
				dragging={true}
				animate={true}
				onViewportChanged={onMove}
			>
				<TileLayer
					url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
				/>
				<MarkerCluster activeItem={activeItem} isHome={isHome}>
				<Marker
					key='esercizio'
					center={[esercizio.esercizio.latitudine, esercizio.esercizio.longitudine]}
					position={[esercizio.esercizio.latitudine, esercizio.esercizio.longitudine]}
					icon={createPinEsercizio('', 'esercizio')}
					// onClick={() => openDetail(appartamento)}
					// data={{ retta, appartamento }}
				>
					<Popup>
						<div style={{ padding: "5px 12px" }}>
							<h3 style={{ marginBottom: 5 }}>{esercizio.esercizio.nome}</h3>
						</div>
					</Popup>
				</Marker>
				{(puntiMappa || []).map(({ appartamento, "retta-minima": retta }) => {
					const fotografie = []
					for(const foto of appartamento.fotografie || []) {
						const src = _.get(foto, "_links.full.href")
						if(src) {
							// ${API_URL}/agenzie/agenzia/${esercizio}/residence/${residence}/appartment/${appartamento}/foto/${foto}
							const a = src.split("/agenzie/agenzia/")[1] //${esercizio}/residence/${residence}/appartment/${appartamento}/foto/${foto}
							const values = a.split("/")
							fotografie.push({ src: `/api/appartamenti/image/${values[0]}/${values[2]}/${values[4]}/${values[6]}` })
						}
					}
					for(const foto of appartamento['residence-fotografie'] || []) {
						const src = _.get(foto, "_links.full.href")
						if(src) {
							// https://rest.mercuriosistemi.com/api/agenzie/agenzia/10/residence/58752/foto/1704402
							const a = src.split("/agenzie/agenzia/")[1]
							const values = a.split("/")
							
							fotografie.push({ src: `/api/appartamenti/residences/image/${values[0]}/${values[2]}/${values[4]}` })
						}
					}

					return (
						<Marker
							key={appartamento.id}
							center={[appartamento.latitudine, appartamento.longitudine]}
							position={[appartamento.latitudine, appartamento.longitudine]}
							icon={createPin(retta, appartamento.id, activeItem == appartamento.id)}
							//onClick={() => openDetail(appartamento)}
							data={{ retta, appartamento }}
						>
							<Popup>

								<div style={{ width: 225, height: 150, borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: "hidden" }}>
									{(fotografie.length > 0) ? (
										<Gallery 
											autoInit
											id={appartamento.id}
											images={fotografie}
											paginationAlign='align_left'
											options={{
												navigation: {
													nextEl: `.swiper-button-next-${appartamento.id}`,
													prevEl: `.swiper-button-prev-${appartamento.id}`,
												},
												pagination: {
													el: `.swiper-pagination-${appartamento.id}`,
													type: 'bullets',
													clickable: true
												}
											}}
										/>
									) : (
										<img
											src={require('../CardAppartamento/img/noimage-bookingmercurio.jpg')}
											alt={'no-image'} className={'wh'}
											style={{ borderTopLeftRadius: 12, borderTopRightRadius: 12 }}
										/>
									)}
								</div>
								<div style={{ padding: "5px 12px" }}>
									<h3 style={{ marginBottom: 5 }}>{appartamento["residence-nome"]}{_.get(appartamento, "nome.testo") && <> - {appartamento.nome.testo}</>}</h3>
									{_.get(appartamento,'posti_max') && <IconText size="medium" className='title-color' text={__('%s posto letto|%s posti letto', appartamento.posti_max)} icon={'icon-bed'} style={{ paddingTop: 0, paddingBottom: 0 }} />}
                	{_.get(appartamento,'camere') != 0 && <IconText size="medium" className='title-color' text={__('%s camera|%s camere', appartamento.camere)} icon={'icon-key'} style={{ paddingTop: 0, paddingBottom: 0 }} />}
									<Button style={{ padding: 5, margin: "5px 0", color: "var(--primary-color)" }} href={`${window.basename}/detail/${appartamento.id}${location}`} label={__('Scopri di più')} bgColor={'secondary-background'} />
								</div>
							</Popup>
						</Marker>
					)
				})}
				</MarkerCluster>
			</LeafletMap>
		</div>
	)
}

export default SearchMap