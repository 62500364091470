import React from "react";
import style from './style.module.css';
import {__} from './../../utils/translator'
import {getRightLegal} from './../../utils';
import _ from 'lodash';
import {noEmpty, telephone} from './../../utils/validation';
import {creditCard_fields} from '../../container/Detail/fieldsForm';

export default class Form extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      form: {},
      errors: {},
      prenotaConCarta: false,
      resetForm: false,
      ...props
    };
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderPrivacy = this.renderPrivacy.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.renderInputText = this.renderInputText.bind(this);
    this.renderInputCheckbox = this.renderInputCheckbox.bind(this);
    this.renderInputSelect = this.renderInputSelect.bind(this);
    this.renderInputRadio = this.renderInputRadio.bind(this);
    this.resetThisForm = this.resetThisForm.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.prenotaConCarta !== prevProps.prenotaConCarta) {
      this.setState({
        prenotaConCarta: this.props.prenotaConCarta
      })
    }
    if (this.props.resetForm !== prevProps.resetForm && this.props.resetForm === true) {
      this.setState({
        resetForm: false
      })
      this.resetThisForm();
    }
  }

  componentDidMount() {
    const {validationFields, fields, privacy, legals, lang} = this.state;
    let privacyFilter = null;

    if (fields) {
      Object.values(fields).map(val => {
        if (_.includes(validationFields.gruppo_validazione, val.name)) {
          if (val.name === 'telefono') {
            val.validation = telephone;
          } else {
            val.validation = noEmpty;
          }
        }
      });
    }

    if (privacy) {
      privacyFilter = privacy.filter(function (field) {
        if (!legals.regole && field.name.includes('regole')) {
          return false;
        }
        if (!legals.newsletter && field.name.includes('newsletter')) {
          return false;
        }

        return true;
      });
      Object.values(privacyFilter).map(val => {
        let link = '';
        if (_.get(legals, val.name)) {
          link = getRightLegal(legals, val.name, lang);
        }
        if (link !== '') {
          val.privacyLink =
            <a className='title-color' href={link} rel="noopener noreferrer" target="_blank">{__('Leggi l\'informativa')}</a>;
        }
      });
    }
    this.setState({fields: fields, privacy: privacyFilter});

  }

  resetThisForm() {
    this.myFormRef.reset();
    this.props.onResetForm();
  }

  renderInputCheckbox(field, errors) {
    return (
      <div className={[style.inputGroup, style[field.class]].join(' ')} key={field.name}>
        <label htmlFor={field.name}>{__(field.label)}{field.validation && __(' *')}</label>
        <input type={field.type} name={field.name} id={field.name} value={field.value} onChange={this.onChange}/>
        {errors[field.name] && <p className={style.error}>{__(errors[field.name])}</p>}
      </div>
    )
  }

  renderInputText(field, errors) {
    return (
      <div className={[style.inputGroup, style[field.class]].join(' ')} key={field.name}>
        <label htmlFor={field.name}>{__(field.label)}{field.validation && __(' *')}</label>
        <input type={field.type} name={field.name} id={field.name} value={field.value} onChange={this.onChange}/>
        {errors[field.name] &&
          <p className={style.error}>{__(errors[field.name])}</p>
        }
      </div>
    )
  }

  renderInputSelect(field, errors) {
    let mese = [];
    let anno = [];
    let obj = [];
    for (let i = 1; i < 13; i++) {
      mese.push(i);
    }
    const date = new Date().getFullYear();
    for (let i = 0; i < 7; i++) {
      anno.push(date + i);
    }
    obj['Mese'] = mese;
    obj['Anno'] = anno;
    return (
      <div className={[style.inputGroup, style[field.class]].join(' ')} key={field.name}>
        <label htmlFor={field.name}>{__('Scadenza')}{field.validation && __(' *')}</label>
        {Object.values(field.options).map((val, indx) => {
          return (
            <select key={indx} name={val} id={val} onChange={this.onChange}>
              <option>{__(val)}</option>
              {obj[val].map((val2, indx2) => {
                return (
                  <option key={indx2} value={val2}>{val2}</option>
                )
              })}
            </select>
          )
        })}
        {errors[field.name] &&
          <p className={style.error}>{__(errors[field.name])}</p>
        }
      </div>
    )
  }

  renderInputRadio(field, errors) {
    return (
      <div className={[style.inputGroup, style[field.class]].join(' ')} key={field.name}>
        <label htmlFor={field.name}>{__(field.label)}{field.validation && __(' *')}</label>
        <div className={style.flex}>
          {Object.values(field.options).map((val, indx) => {
            return (
              <label key={indx}>
                <input type={field.type} name={field.name} id={val} value={val} onChange={this.onChange}/>
                {val}</label>
            )
          })}
        </div>
        {errors[field.name] &&
          <p className={style.error}>{__(errors[field.name])}</p>
        }
      </div>
    )
  }

  renderInputTextarea(field, errors) {
    return (
      <div className={[style.inputGroupBlock, style[field.class]].join(' ')} key={field.name}>
        <label htmlFor={field.name}>{__(field.label)}{field.validation && __(' *')}</label>
        <textarea type={field.type} name={field.name} id={field.name} value={field.value} onChange={this.onChange}/>
        {errors[field.name] &&
          <p className={style.error}>{__(errors[field.name])}</p>
        }
      </div>
    )
  }

  renderPrivacy(field, errors) {

    return (
      <div className={[style.inputGroupPrivacy, style[field.class]].join(' ')} key={field.name}>
        <input type={field.type} name={field.name} id={field.name} value={field.value} onChange={this.onChange}/>
        <label htmlFor={field.name}>{__(field.label)}{field.validation && __(' *')}</label>{field.privacyLink && ' - '}{field.privacyLink}
        {errors[field.name] && <p className={style.error}>{__(errors[field.name])}</p>}
      </div>
    )
  }

  onChange(e) {
    const target = e.target;
    const {form} = this.state;
    if (e.target.type === 'checkbox') {
      if (e.target.checked) {
        form[target.name] = e.target.checked;
      } else {
        delete form[target.name];
      }

    } else {
      form[target.name] = target.value;
    }

    this.setState({form});
  }

  validateFields() {
    const {fields, privacy, form, errors, prenotaConCarta} = this.state;

    const email = form.email;
    const confirm_email = form.confirm_email;

    if (confirm_email !== email) {
      errors['confirm_email'] = "email diverse";
      errors['email'] = "email diverse";
    } else {

      // Valido i campi del form
      [].concat(fields).forEach(field => {
        if (field.validation) {
          const isValid = field.validation(form[field.name]);
          if (isValid !== true) {
            errors[field.name] = isValid;
          } else {
            delete errors[field.name];
          }
        }
      });

      if (prenotaConCarta) { //da aggiungere controllo sulla var booleana che mostra o meno i campi della carta di credito
        // Valido la carta di credito
        [].concat(creditCard_fields).forEach(field => {
          if (field.validation) {
            if (field.type === 'select' && Object.values(field.options).length > 0) {
              let isValid = true;
              Object.values(field.options).map((fld, indx) => {
                if (isValid === true) {
                  isValid = field.validation(form[fld]);
                }
              });
              if (isValid !== true) {
                errors[field.name] = isValid;
              } else {
                delete errors[field.name];
              }
            } else {
              const isValid = field.validation(form[field.name]);
              if (isValid !== true) {
                errors[field.name] = isValid;
              } else {
                delete errors[field.name];
              }
            }
          }
        });
      }

      // Valido la privacy
      [].concat(privacy).forEach(field => {
        if (field.validation) {
          const isValid = field.validation(form[field.name]);
          if (isValid !== true) {
            errors[field.name] = isValid;
          } else {
            delete errors[field.name];
          }
        }
      });
    }

    this.setState({errors});
    return Object.keys(errors).length > 0 ? false : true;
  }

  handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    const form = this.state;

    const isValid = this.validateFields(form);
    if (isValid) {
      const formfields = form.form;
      let newsletter = ' NO';
      let note = ' ';
      Object.keys(formfields).map((val, indx) => {
        if (val.includes('newsletter') && formfields[val] === true) {
          newsletter = ' SI';
        }
        if (val.includes('note') && formfields[val] !== undefined) {
          note = formfields.note;
        }
        return null;
      });

      let carta = {};
      if (form.prenotaConCarta) {
        carta = {
          'carta': {
            'owner': formfields.intestatario,
            'type': formfields.tipo_carta,
            'cardnumber': formfields.card_number,
            'cvv': formfields.cvv,
            'expirydate': `${formfields.Mese}\/${formfields.Anno}`
          }
        }
      }

      let orderedFormfields = {
        'note': `${note} - Accettazioni_Newsletter:${newsletter}`,
        'cliente': {
          'nome': formfields.nome,
          'cognome': formfields.cognome,
          'indirizzo': formfields.indirizzo,
          'citta': formfields.citta,
          'cap': formfields.cap,
          'provincia': formfields.provincia,
          'nazione': formfields.nazione,
          'telefono': formfields.telefono,
          'email': formfields.email,
          'lingua': form.lang
        },
        ...carta
      };
      return this.props.onSubmit(orderedFormfields, form.errors);
    }
  }

  render() {

    const self = this;
    const {fields, privacy, errors, prenotaConCarta, buttonLabel, legals} = this.state;
    if (!fields) return null;

    return (
      <form className={style.form} onSubmit={this.handleSubmit} ref={(el) => this.myFormRef = el}>
        {fields.map(field => {
          switch (field.type) {
            case 'text':
              return self.renderInputText(field, errors);
            case 'textarea':
              return self.renderInputTextarea(field, errors);
            case 'email':
              return self.renderInputText(field, errors);
            case 'checkbox':
              return self.renderInputCheckbox(field, errors);
            default:
              break;
          }
        })}
        {(prenotaConCarta && creditCard_fields) &&
          <div className={style.pagaconcarta}>
            <h4 className={'title-color'}>{__('Dati carta di credito')}</h4>
            <div className={style.pagaconcartaInner}>
              {creditCard_fields.map(field => {
                switch (field.type) {
                  case 'text':
                    return self.renderInputText(field, errors);
                  case 'select':
                    return self.renderInputSelect(field, errors);
                  case 'radio':
                    return self.renderInputRadio(field, errors);
                  case 'checkbox':
                    return self.renderInputCheckbox(field, errors);
                  default:
                    break;
                }
              })}</div>
          </div>
        }
        <div className={style.privacy}>
          {
            privacy.map(field => {
              switch (field.type) {
                case 'checkbox':
                  return self.renderPrivacy(field, errors);
                default:
                  break;
              }
            })}
        </div>
        <div className='align_right w-100'>
          <input className={[style.button, 'secondary-background', 'round-btn'].join(' ')} type="submit" value={buttonLabel}/>
        </div>
      </form>
    )
  }
}

