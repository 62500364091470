import React from "react";
import style from './style.module.css';
import { __ } from './../../utils/translator'

export default class Button extends React.Component{

  render (){
    const { label, bgColor, onClick, href, title, width, active = true} = this.props;

    if (href) {
      return (
        <a href={href} title={title} className={[style.button, style[width], bgColor,'round-btn',(active)?'':style.opacity_05].join(' ')} style={{ ...this.props.style }}>{__(label)}</a>
      )
    }
    return (
      <button onClick={onClick} className={[style.button, style[width], bgColor,'round-btn',(active)?'':style.opacity_05].join(' ')} style={{ ...this.props.style }}>
        {__(label)}
      </button>
    )
  }
}

