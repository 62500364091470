import React from "react"
import styles from "./style.module.scss"

export default ({ onClick, bgColor="primary-background", textColor = "primary-color", label, icon }) => {
	return (
		<div className={[styles.iconButton, bgColor].join(" ")} style={{  }} onClick={onClick}>
			<div className={styles.buttonContent}>
				<i style={{ fontSize: 32, marginRight: 10 }} className={[icon, textColor].join(" ")} />
				<span className={textColor}>{label}</span>
			</div>
		</div>
	)
}