import React from "react";
import style from './style.module.css';
import Appartamenti from './../../services/appartamenti';
import IconText from '../../components/IconText';
import { __ } from "../../utils/translator";
import _ from 'lodash';
import ServizioAggiuntivo from "../ServizioAggiuntivo";

export default class ServiziAggiuntivi extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      ...props
    }
    
    this.appartamenti = new Appartamenti();
    this.onChangeVal = this.onChangeVal.bind(this);
  }
  
  componentDidMount(){
    const { href , esercizio } = this.state;
    const self = this;

    if(href !== ''){
      this.fetchData(href);
    }
  }
  
  componentDidUpdate(prevProps, prevState){
    if (prevProps.href !== this.props.href) {
      const href = this.props.href;
      this.setState({
        href: href,
        servizi_aggiuntivi: ''
      });
      this.props.onChangeServizi('');
      
      if(href !== ''){
        this.fetchData(href);
      }
    } 
  }

  fetchData(href) {
    if(href !== '') {
      const self = this;
      const { esercizio } = this.state;
  
      this.appartamenti.getInfoHref(esercizio,href)
      .then(res => {
  
        const { data } = res;
        if (Object.values(data).length > 0) {
          self.setState({
            servizi_aggiuntivi: data
          });
          this.props.onChangeServizi(data);
        }
      })
      .catch(err => {
        console.log(err);
      });
    }
  }

  onChangeVal(id,servizio){
    const { servizi_aggiuntivi } = this.state;

    servizi_aggiuntivi[id] = _.merge(servizi_aggiuntivi[id],servizio);
    
    this.setState({ servizi_aggiuntivi: servizi_aggiuntivi});
    this.props.onChangeServizi && this.props.onChangeServizi(servizi_aggiuntivi);
  }


  render (){
    
    const { className, servizi_aggiuntivi } = this.state;
    
    if (!servizi_aggiuntivi || Object.values(servizi_aggiuntivi).length <= 0) return <div className={style.spacer}></div>;
    return (
      <div className={[className,style.servizi_a_pagamento].join(' ')}>
        <div className={[style.titolo, 'title-color'].join(' ')}>
          <h2><IconText text={__('Servizi Aggiuntivi')} icon={'icon-star'} className={'title-color'} /></h2>
        </div>
        <div className={style.text}>
          {(servizi_aggiuntivi && Object.values(servizi_aggiuntivi).length > 0) && Object.values(servizi_aggiuntivi).map((val, indx) => 
            { 
              const id = _.get(val,'servizio.id',indx);
              return(
                <ServizioAggiuntivo 
                  key={indx}
                  id={id}
                  giorni={val.giorni} 
                  max={val.max} 
                  persone={val.persone} 
                  prezzo={val.prezzo} 
                  servizio={val.servizio} 
                  onChangeVal={this.onChangeVal} />
              )
            }
            
          )}
        
        </div>
      </div>
    )
  }
}
