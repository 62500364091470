import {getRightLang, getRandomInt} from './../utils';
import HttpClient from './HttpClient';
import _, {find} from "lodash";

export default class Esercizio {

  constructor(context) {
    const esercizio = window.esercizio;
    esercizio && Object.keys(esercizio).forEach(key => {
      this[key] = esercizio[key];
    });
    this.httpClient = new HttpClient(context);
  }

  getLogo() {
    return (this._links && this._links.logo && this._links.logo.href) || '';
  }

  getRecapitoAggiuntivo(tipo) {

    let response = '';

    this.recapitiaggiuntivi && Object.keys(this.recapitiaggiuntivi).forEach(key => {

      if (this.recapitiaggiuntivi[key].tipo == tipo) {

        response = this.recapitiaggiuntivi[key].valore;
      }

    });

    return response;
  }

  getMenu() {
    return getRightLang(this.configurazioni, 'menu_booking', {}, this.lang);
  }

  getIndirizzo() {
    return this.esercizio.indirizzo || '';
  }

  getNumero() {
    return this.esercizio.numero || '';
  }

  getCitta() {
    return this.esercizio.citta.nome || '';
  }

  getTelefono() {
    return this.esercizio.telefono || '';
  }

  getNome() {
    return this.esercizio.nome || '';
  }

  getEmail() {
    return this.esercizio.email || '';
  }

  getLatitudine() {
    return parseFloat(this.esercizio.latitudine) || '';
  }

  getLongitutine() {
    return parseFloat(this.esercizio.longitudine) || '';
  }

  getCookieScriptElement() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.setAttribute("data-cookiescriptreport", 'report');
    s.src = `https://report.cookie-script.com/r/${this.configurazioni.cookie_script_id}.js`;

    return s;
  }

  getBannerInfo(cosa = 'bannerHome') {
    let banner = getRightLang(this.configurazioni, cosa, {}, this.lang);
    if (Array.isArray(banner.image)) {
      banner.image = banner.image[getRandomInt(0, banner.image.length - 1)]
    }
    if (banner.image === 'random') {
      banner.image = 'https://photobe.mercuriosistemi.com/' + getRandomInt(1, 9) + '.jpg';
    }

    return banner;
  }

  getLegalContent(type) {
    return this.httpClient.get(`/legal/${this.lang}/${type}/${this.id}`);
  }

  getAgencyInfo(type) {
    return this.httpClient.get(`/legal/${this.lang}/informazione/${type}/${this.id}`);
  }

  setLanguage() {
    const browserLanguage = window.navigator.language || navigator.language || navigator.userLanguage;
    let newLang = browserLanguage.split('-')[0];

    //window.location.assign(path);
    //if(this.languages.indexOf(newLang) !== -1) {
    this.lang = newLang;
    return this.lang;
    //}
  }
}
