import React from "react";
import Header from './header';
import Pagination from './../Pagination';
import style from './style.module.css';
import Empty from './empty';
import { __ } from "../../utils/translator";
import Button from "../../components/Button";
import Modal from "react-modal";
import _ from "lodash"
import Filter from '../../components/Filter'
import FilterIcon from '../../components/Icons/FilterIcon'

export default class List extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ...props,
      filtersModalOpen: false
    };

    this.onPageChanged = this.onPageChanged.bind(this);
    this.updateLimitValue = this.updateLimitValue.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.items !== prevState.items || nextProps.loading !== prevState.loading) {
      return {
        items: nextProps.items,
        pageLimit: nextProps.pageLimit,
        currentPage: nextProps.currentPage,
        loading: nextProps.loading,
        pages: nextProps.pages,
        total: nextProps.total
      };
    }
    else return null;
  }

  onPageChanged = data => {
    const { currentPage } = this.state;
    if (currentPage !== data.currentPage) {
      const { pageLimit } = data;
      const offset = (data.currentPage - 1) * pageLimit;
      this.props.onPageChanged(pageLimit, offset, data.currentPage);
    }
  }

  updateLimitValue = e => {
    const self = this;
    const target = e.target;
    const newPageLimit = parseInt(target.value);
    const { pageLimit } = this.state;
    if (pageLimit !== newPageLimit) {
      this.setState({ pageLimit: newPageLimit }, () => {
        self.props.onUpdateLimitChanged(newPageLimit);
      });
    }
  }

  render() {

    const {
      items,
      currentPage,
      pages,
      pageLimit,
      total,
      loading = true,
      ordineTipo,
      ordineDirezione,
      ordineDirezioneDefault,
      ordineTipoDefault,
      esercizio,
      hideHeader
    } = this.state;

    const showFiltersSelect = this.props.enabledFilters && Object.values(this.props.enabledFilters).length > 0

    const noResults = (typeof (items) !== 'object' || !items || items.length === 0)

    /* if (noResults && !loading) {
      return (
        <Empty {...{ esercizio }} />
      );
    } */

    let isloaded = '';
    if (!loading) {
      isloaded = 'completed';
    }
    const loaderClasses = ['loader', isloaded].join(' ');

    const filtersView = <div style={{ display: 'flex', alignItems: 'center' }}>
      {showFiltersSelect &&
        <div
          onClick={() => {
            this.setState({ filtersModalOpen: true })
          }}
          style={{
            borderRadius: 5,
            cursor: 'pointer',
            overflow: 'hidden',
            border: '1px solid var(--primary-background)',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ alignSelf: 'stretch', width: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="primary-background">
              <FilterIcon color={"var(--primary-color)"}/>
            </div>
            <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 15, paddingRight: 15, }}>{__("Filtri")}</div>
          </div>
        </div>
      }
    </div>

    return (
      <div className={style.listContainer}>
        {
          noResults && !loading ?
            <div>
              {filtersView}
              <Empty {...{ esercizio }} />
            </div>
            :
            <div className={style.pagination}>
              {(showFiltersSelect || (!hideHeader/*  && pageLimit < parseInt(total) */)) &&
                <div className={style.listHeader}>
                  <div style={{ display: 'flex', whiteSpace: 'nowrap', alignItems: 'center', flexWrap: 'wrap' }}>
                    {!hideHeader ?
                      <Header
                        updateLimitValue={this.updateLimitValue}
                        onSortDirectionChange={this.props.onSortDirectionChange}
                        onSortByChange={this.props.onSortByChange}
                        ordineDirezioneDefault={ordineDirezioneDefault}
                        ordineTipoDefault={ordineTipoDefault}
                        {...{ currentPage, pages, ordineTipo, ordineDirezione }}
                        additionalItem={filtersView}
                      />
                    : filtersView}
                  </div>
                  {!noResults && <Pagination
                    totalRecords={parseInt(total)}
                    pageLimit={pageLimit}
                    pageNeighbours={2}
                    onPageChanged={this.onPageChanged}
                    currentPage={currentPage}
                  />}
                </div>
              }

              <section className={style.list}>
                {items && items.map(element => {  //!loading && 
                  return this.props.onItemRender(element);
                })}
                <div className={loaderClasses}><div className='spinner'><div></div><div></div></div></div>
              </section>

              {!noResults && <Pagination
                totalRecords={parseInt(total)}
                pageLimit={pageLimit}
                pageNeighbours={2}
                onPageChanged={this.onPageChanged}
                currentPage={currentPage}
              />}
              <div className={'clear'}></div>
            </div>
        }
        <Modal
          isOpen={this.state.filtersModalOpen}
          onRequestClose={() => this.setState({ filtersModalOpen: false })}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              display: "flex",
              flexDirection: "column",
              maxWidth: 700,
              minWidth: 350,
              maxHeight: "90%",
              overflowY: "scroll",
            }
          }}
        >
          <div onClick={() => this.setState({ filtersModalOpen: false })}>
            <i className="icon-close" />
          </div>
          <div style={{ alignSelf: 'center', margin: 30, fontWeight: 'bold', fontSize: 24 }}>{__("Filtri disponibili")}</div>
          <div style={{ display: 'flex', alignSelf: 'stretch', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginLeft: 20, marginRight: 20 }}>
            {
              this.props.enabledFilters && Object.values(this.props.enabledFilters).map((filter, index) => {
                return (
                  <Filter
                    key={`${index}`}
                    filter={filter}
                    onFiltersChange={this.props.onFiltersChange}
                    selectedFilters={this.props.selectedFilters}
                  />
                )
              })
            }

          </div>
          <Button label="Applica" bgColor={'secondary-background'} width="auto" style={{ alignSelf: 'center', marginBottom: 20, marginTop: 15 }} onClick={() => {
            this.setState({ filtersModalOpen: false })
            this.props.onFiltersApplied()
          }} />
        </Modal>
      </div>
    )
  }
}

